import Onboarding from './pages/onboarding';
import Claims from '../src/pages/claims'
import Giving from '../src/pages/giving'
import GivingDetails from './pages/giving/details';
import GivingTypes from './pages/giving/givingTypes';
import Tickets from '../src/pages/tickets/tickets';
import TicketDetails from './pages/tickets/ticketDetails';
import AddGiving from './pages/giving/add';
import Groups from '../src/pages/groups'
import GroupDetails from '../src/pages/groups/details'
import DashboardHome from '../src/pages/home'
import LandingPage from '../src/pages/web/home'
import Support from '../src/pages/web/support/index'
import Doc from '../src/pages/web/doc/index'
import Terms from '../src/pages/web/terms/index'
import Members from '../src/pages/members'
import ExitedMembers from '../src/pages/members/exited'
import MemberDetails from '../src/pages/members/details'
import ExitedMemberDetails from '../src/pages/members/exited-details'
import Report from '../src/pages/report'
import Settings from '../src/pages/settings'
import Account from '../src/pages/account/index'
import Login from '../src/pages/auth/login'
import Reset from '../src/pages/auth/reset'
import ResetDone from '../src/pages/auth/resetdone'
import Logout from '../src/pages/auth/logout'
import './App.css';
import {
  BrowserRouter as Router,
  Routes,
  Route
} from 'react-router-dom';
import { useState } from 'react';

function App() {
  const [base, setBase] = useState(sessionStorage.getItem('prefix') ? sessionStorage.getItem('prefix'): 'user')
  return (


    <Router>
    <div className="App bg-white">
      <Routes>
        <Route path='/' element={< LandingPage />}></Route>
        <Route path='/doc' element={< Doc />}></Route>
        <Route path='/terms-and-conditions' element={< Terms />}></Route>
        <Route path='/support' element={< Support />}></Route>
        <Route path='/onboard' element={< Onboarding />}></Route>
        <Route path='/login' element={< Login />}></Route>
        <Route path='/reset' element={< Reset />}></Route>
        <Route path='/reset-done' element={< ResetDone />}></Route>
        <Route path='/logout' element={< Logout />}></Route>
        <Route path='*' element={< Login />}></Route>
        sessionStorage.getItem('role') ?
        sessionStorage.getItem('role') == 1 ? 
        <>
        //superadmin
        <Route path={'/'+base} element={< DashboardHome />}></Route>
        <Route path={'/'+base+'/dashboard'} element={< DashboardHome />}></Route>
        <Route path={'/'+base+'/exited-members'} element={< ExitedMembers />}></Route>
        <Route path={'/'+base+'/members'} element={< Members />}></Route>
        <Route path={'/'+base+'/exited-member-details'} element={< ExitedMemberDetails />}></Route>
        <Route path={'/'+base+'/member-details'} element={< MemberDetails />}></Route>
        <Route path={'/'+base+'/claims'} element={< Claims />}></Route>
        <Route path={'/'+base+'/giving'} element={< Giving />}></Route>
        <Route path={'/'+base+'/giving-details'} element={< GivingDetails />}></Route>
        <Route path={'/'+base+'/giving-types'} element={< GivingTypes />}></Route>
        <Route path={'/'+base+'/tickets'} element={< Tickets />}></Route>
        <Route path={'/'+base+'/ticket/details'} element={< TicketDetails />}></Route>
        <Route path={'/'+base+'/add-giving'} element={< AddGiving />}></Route>
        <Route path={'/'+base+'/groups'} element={< Groups />}></Route>
        <Route path={'/'+base+'/group-details'} element={< GroupDetails />}></Route>
        <Route path={'/'+base+'/report'} element={< Report />}></Route>
        <Route path={'/'+base+'/settings'} element={< Settings />}></Route>
        <Route path={'/'+base+'/account'} element={< Account />}></Route>
        </>
        : sessionStorage.getItem('role') == 2 ?
        <>
        //admin
        <Route path={'/'+base} element={< DashboardHome />}></Route>
        <Route path={'/'+base+'/dashboard'} element={< DashboardHome />}></Route>
        <Route path={'/'+base+'/members'} element={< Members />}></Route>
        <Route path={'/'+base+'/member-details'} element={< MemberDetails />}></Route>
        <Route path={'/'+base+'/giving'} element={< Giving />}></Route>
        <Route path={'/'+base+'/giving-details'} element={< GivingDetails />}></Route>
        <Route path={'/'+base+'/add-giving'} element={< AddGiving />}></Route>
        <Route path={'/'+base+'/settings'} element={< Settings />}></Route>
        <Route path={'/'+base+'/account'} element={< Account />}></Route>
        </>
        : sessionStorage.getItem('role') == 3 ?
        <>
        //user
        <Route path={'/'+base} element={< DashboardHome />}></Route>
        <Route path={'/'+base+'/dashboard'} element={< DashboardHome />}></Route>
        <Route path={'/'+base+'/members'} element={< Members />}></Route>
        <Route path={'/'+base+'/member-details'} element={< MemberDetails />}></Route>
        <Route path={'/'+base+'/giving'} element={< Giving />}></Route>
        <Route path={'/'+base+'/giving-details'} element={< GivingDetails />}></Route>
        <Route path={'/'+base+'/add-giving'} element={< AddGiving />}></Route>
        <Route path={'/'+base+'/settings'} element={< Settings />}></Route>
        <Route path={'/'+base+'/account'} element={< Account />}></Route>
        </>
        : 
        //member
        <Route path={'/'+base+'/member-details'} element={< MemberDetails />}></Route>
        <Route path={'/'+base+'/settings'} element={< Settings />}></Route>
        <Route path={'/'+base+'/account'} element={< Account />}></Route>
        :null
      </Routes>
    </div>
  </Router>
  );
}

export default App;
