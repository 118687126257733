import React, { useState } from 'react'
import RolesBody from './rolesBody'
import Permissions from './permissions'

function Roles() {
  const [page, setPage] = useState(1)
  return (
    <>
      <div className='flex flex-col'>
        <div className='flex flex-row border-b-[1px] space-x-3'>
          <span onClick={() => setPage(1)} className={`px-2 ${page == 1 ? 'text-[#B30000] border-b-[1px] border-[#B30000]' : ''} cursor-pointer pb-1`}>Admins</span>
          <span onClick={() => setPage(2)} className={`px-2 ${page == 2 ? 'text-[#B30000] border-b-[1px] border-[#B30000]' : ''} cursor-pointer pb-1`}>Permission</span>
        </div>
      </div>
      {page == 1 ? <RolesBody></RolesBody> : <Permissions></Permissions>}
      </>
  )
}

export default Roles