import React from 'react'
import Layout from '../../layout'

function Claims() {
  return (
    <>
        <Layout props='claims'></Layout>
    </>
  )
}

export default Claims