import React, { useEffect, useState } from "react";
import upload from "../../../images/icons/upload.svg";
import axios from "axios";
import settings from "../../../components/getrequest";
import { format } from 'date-fns';

function General() {
      let urlbase = process.env.REACT_APP_BASE;
  const [chooseFile, setChooseFile] = useState();
  const [details, setDetails] = useState({});
   const selectFile = async (event) => {
     const fileInput = document.getElementById("file");
     fileInput.click();
   };
  const detail = async () => {
    axios
      .get(urlbase + "/api/org/details", settings())
      .then((res) => {
        if (res.data.code === 200) {
          setDetails(res.data.data)
          sessionStorage.setItem('chgamsEntImage',res.data.message)
        } else {
          console.log("Error");
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  useEffect(() => {
    detail();
  }, []);

  sessionStorage.setItem('chgamsUploadedFile',null);
  const handleFileChange = async (event) => {
    const file = event.target.files[0];
    if (file) {
      setChooseFile(1)
      const formData = new FormData();
      formData.append("image", file);
      console.log(formData);
      axios
        .post(urlbase + "/api/org/logo", formData, settings())
        .then((res) => {
          sessionStorage.setItem('chgamsUploadedFile',res.data.data.image)
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      console.log("No file selected.");
    } 
  };
  function isValidPostcode(postcode) {
    const postcodeRegex = /^[A-Z][A-Z\d]{1,3}\s\d[A-Z]{2}$/
    return postcodeRegex.test(postcode);
  }

  const [updateDetails, setUpdateDetails] = useState({});
  const [addingProcessing,setAddingProcessing] = useState()
  const handleSubmit= (event) => {
    if(updateDetails.post_code){
      if(!isValidPostcode(updateDetails.post_code)){
        alert('Invalid post code')
        return
      }
    }
    event.preventDefault();
    updateDetails.image = sessionStorage.getItem('chgamsUploadedFile') ? sessionStorage.getItem('chgamsUploadedFile') : null
    if(updateDetails.start_date){
      updateDetails.end_date = sessionStorage.getItem('end_date')
      var theDate = new Date(sessionStorage.getItem('start_date'));
      var theYear = theDate.getFullYear();

      var theLastDate = new Date(sessionStorage.getItem('end_date'));
      var theLastYear = theLastDate.getFullYear();

      var nextYear = (new Date()).getFullYear() + 1;
      var lastYear = (new Date()).getFullYear() - 1;
      if (theYear < lastYear) {
        alert("Start date of financial year cannot be less than last year");
        return
      }
      if (theLastYear > nextYear) {
        alert("End date of financial year cannot be greater than next year");
        return
      }
    } 
    setAddingProcessing(1)
    axios
      .post(urlbase + "/api/org/update",updateDetails, settings())
      .then((res) => {
        if(res.data.code == 200){
          sessionStorage.setItem('chgamsEntImage',res.data.message)
          alert('Update successful')
          window.location.reload()
        }else if(res.data.code == 201){
          alert('No changes were made')
        }else{
          alert(res.data.message);
        }
        setAddingProcessing(0)
        setChooseFile(0)
      })
      .catch((error) => {
        console.log(error);
        alert('There was an error, please try again later');
        setAddingProcessing(0)
      });
  }

  const handleInput = (e,field) => {
    setUpdateDetails({...updateDetails,
    [field] : e.target.value})
  }

  const [endDate, setEndDate] = useState()
  const calculateDate = (e) => {
    handleInput(e,'start_date')
    let today = new Date(e.target.value)
    let dat = today.setMonth(today.getMonth() + 12)
    dat = new Date(dat);
    dat.setDate(dat.getDate() - 1);
    let end = (new Date(dat)).toISOString().slice(0, 10)
    sessionStorage.setItem('start_date',today)
    sessionStorage.setItem('end_date',end)
    setEndDate(end) 
}

  return (
    <>
      <div className="flex flex-row justify-between items-center border-b-[1px] pb-7 text-[#4F4F4F]">
        <div className="flex flex-col space-y-2">
          <span className="text-[20px] font-[600] text-[#121927]">
            Company Profile
          </span>
          <span className="text-[#4F4F4F] font-[400] text-[16px]">
            Update company’s profile here
          </span>
        </div>
        {addingProcessing ? 'Please wait...' : 
            <button className="bg-[#B30000] p-2 text-white rounded-[4px]" type="button" onClick={(e) => handleSubmit(e)}>
            Save Changes
          </button>
        }
      </div>
      <div className="grid grid-cols-8 items-start gap-y-0 space-y-3 lg:space-y-0 lg:space-x-10 border-b-[1px] pb-7 text-[#4F4F4F]">
        <div className="col-span-8 lg:col-span-2 flex flex-col top-0 mt-0">
          <span className="text-[16px] font-[600] text-[#121927]">
            Company Information
          </span>
          <span className="text-[#4F4F4F] font-[400] text-[16px]">
            Update company’s info here
          </span>
        </div>
        <div className="col-span-8 lg:col-span-6 space-y-4">
          <div className="flex flex-col space-y-4 lg:space-y-0 lg:flex-row lg:space-x-4">
            <div className="flex flex-col space-y-2 w-full">
                <label>Reference</label>
                <input
                  className="p-2 text-[#4F4F4F] border-[1px] rounded-[8px] outline-none"
                  defaultValue={details.hmrc_reference}
                  onChange={(e) => handleInput(e,'hmrc_reference')}
                />
              </div>
              <div className="flex flex-col space-y-2 w-full">
                <label>Registration Number</label>
                <input
                  className="p-2 text-[#4F4F4F] border-[1px] rounded-[8px] outline-none"
                  defaultValue={details.charity_reg_no}
                  onChange={(e) => handleInput(e,'charity_reg_no')}
                />
            </div>
          </div>
          <div className="flex flex-col space-y-4 lg:space-y-0 lg:flex-row lg:space-x-4">
            <div className="flex flex-col space-y-2 w-full">
                <label>Start Date</label>
                <input
                  type="date"
                  className="p-2 text-[#4F4F4F] border-[1px] rounded-[8px] outline-none"
                  defaultValue={details.start_date ? new Date(details.start_date).toISOString().split('T')[0]: ''}
                  onChange={(e) => calculateDate(e,'start_date')}
                />
              </div>
              <div className="flex flex-col space-y-2 w-full">
                <label>End Date</label>
                <input
                  readOnly
                  className="p-2 text-[#4F4F4F] border-[1px] rounded-[8px] outline-none"
                  defaultValue={ endDate ? endDate : details.end_date ? new Date(details.end_date).toLocaleDateString('en-GB') : ''}
                  onChange={(e) => handleInput(e,'end_date')}
                />
            </div>
          </div>
          <div className="flex flex-col space-y-2">
            <label>Name</label>
            <input
              className="p-2 text-[#4F4F4F] border-[1px] rounded-[8px] outline-none"
              defaultValue={details.name}
              onChange={(e) => handleInput(e,'name')}
            />
          </div>
          <div className="flex flex-col space-y-2">
            <label>Description</label>
            <textarea
              className="p-2 text-[#4F4F4F] border-[1px] h-[128px] rounded-[8px] outline-none"
              defaultValue={details.description}
              onChange={(e) => handleInput(e,'description')}
            ></textarea>
          </div>
        </div>
      </div>
      <div className="grid grid-cols-8 items-start gap-y-0 space-y-3 lg:space-y-0 lg:space-x-10 border-b-[1px] pb-7 text-[#4F4F4F]">
        <div className="col-span-8 lg:col-span-2 flex flex-col top-0 mt-0">
          <span className="text-[16px] font-[600] text-[#121927]">
            Contact Information
          </span>
          <span className="text-[#4F4F4F] font-[400] text-[16px]">
            Update contact information here
          </span>
        </div>
        <div className="col-span-8 lg:col-span-6 space-y-4">
          <div className="grid grid-cols-2 gap-x-3 gap-y-4">
            <div className="col-span-2 lg:col-span-1 flex flex-col space-y-2">
              <label>Email Address</label>
              <input
                className="p-2 text-[#4F4F4F] border-[1px] rounded-[8px] outline-none"
                defaultValue={details.email}
                readOnly
              />
            </div>
            <div className="col-span-2 lg:col-span-1 flex flex-col space-y-2">
              <label>Post Code</label>
              <input
                className="p-2 text-[#4F4F4F] border-[1px] rounded-[8px] outline-none"
                defaultValue={details.post_code}
                onChange={(e) => handleInput(e,'post_code')}
              />
            </div>
            <div className="col-span-2 lg:col-span-1 flex flex-col space-y-2">
              <label>Country</label>
              <input
                className="p-2 text-[#4F4F4F] border-[1px] rounded-[8px] outline-none"
                defaultValue={details.country}
                onChange={(e) => handleInput(e,'country')}
                readOnly
              />
            </div>
            <div className="col-span-2 lg:col-span-1 flex flex-col space-y-2">
              <label>State/County</label>
              <input
                className="p-2 text-[#4F4F4F] border-[1px] rounded-[8px] outline-none"
                defaultValue={details.state}
                onChange={(e) => handleInput(e,'state')}
              />
            </div>
          </div>
          <div className="flex flex-col space-y-2">
            <label>Address</label>
            <textarea
              className="p-2 text-[#4F4F4F] border-[1px] h-[128px] rounded-[8px] outline-none"
              defaultValue={details.address_description}
              onChange={(e) => handleInput(e,'address_description')}
            ></textarea>
          </div>
        </div>
      </div>
      <div className="grid grid-cols-8 items-start space-y-3 lg:space-y-0 lg:space-x-10 pb-7 text-[#4F4F4F]">
        <div className="col-span-8 lg:col-span-2 flex flex-col top-0 mt-0">
          <span className="text-[16px] font-[600] text-[#121927]">
            Company Logo
          </span>
          <span className="text-[#4F4F4F] font-[400] text-[16px]">
            Update company’s logo here
          </span>
        </div>
        <div className="col-span-8 lg:col-span-6 space-y-4">
          <div className="flex flex-col space-y-2">
            <div
              onClick={selectFile}
              className="p-2 flex flex-col cursor-pointer text-[#4F4F4F] items-center justify-center space-y-1 border-[1px] border-dashed h-[128px] rounded-[8px] w-[100%] lg:w-[50%]"
            >
              <span>
                <img className="w-[32px] h-[32px]" src={upload} />
              </span>
              <span>
                { !chooseFile? <span>Upload a new image(5mb Max){` `}</span>  : <span>File selected, change{` `}</span>}
                <span className="text-[#B30000]">here</span>
              </span>
              <span className="text-[#828282] text-[14px]">
                SVG PNG JPEG or JPG
              </span>
              <input id="file" hidden type="file" onChange={handleFileChange} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default General;
