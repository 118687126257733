import React, { useEffect, useState } from 'react'
import logo from '../../images/logo.svg'
import reset from '../../images/reset.svg'

function Reset() {
    useEffect(() => {
        if(!sessionStorage.getItem('chgamsresetdone')){
            window.location.href='/login'
        }
    },[])

  function login(){
    sessionStorage.clear()
    window.location.href='/login'
}

  return (
    <>
        <div className='grid grid-cols-2'>
          <div className='col-span-2 px-5 lg:px-20 flex flex-col space-y-10 lg:space-y-20 pt-5'>
            <span><img src={logo} /></span>
            <div className='flex flex-col space-y-4 pt-10 text-center mx-auto lg:w-[50%] w-[90%]'>
              <div className='flex flex-col space-y-2'>
                <span className='mx-auto'><img src={reset} /></span>
                <span className='text-[#090712] text-[32px] font-[500]'>Password reset successful</span>
                <span className='text-[#828282] text-[16px] font-[400]'>Your password reset was successful. Please log in using your newly set password for secure access.</span>
              </div>
              <div className='grid grid-cols-2 text-[#505050] text-[16px] text-start gap-x-4 gap-y-5'>
                    <div className='col-span-2 flex flex-col mx-auto w-full'>
                        <div className='flex flex-row justify-between'>
                        </div>
                        <div className='w-full pt-5'><button onClick={login} className='bg-[#B30000] text-white w-full h-[50px] rounded-[4px]'>Login now</button></div>
                    </div>
                </div>
            </div>
          </div>
        </div>
    </>
  )
}

export default Reset