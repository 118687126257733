import React from 'react'
import Layout from '../../layout'

function ExitedMembers() {
  return (
    <>
        <Layout props='exited-members'></Layout>
    </>
  )
}

export default ExitedMembers