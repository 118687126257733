import React, { useState } from "react";
import Header from '../header'
import Hero from './hero'
import Body from './body'
import Footer from '../footer'

function HomePage() {
  const [terms, setTerms] = useState(1)
  return (
    <>
    <div className=''>
      <header><Header></Header></header>
      <body>
        <div><Hero></Hero></div>
        <div>
         <Body/>
        </div>
      </body>
      <footer>
        <Footer/>
      </footer>
      {
          terms? 
          <div class="sticky bottom-0 z-50 lg:w-[30%] w-[100%] mt-[-140px] text-right mx-auto z-50">
            <div className="relative flex flex-col space-y-5 bg-[#FFF7F7] rounded-[8px] text-[#B30000]">
              <span onClick={()=>setTerms(0)} className="absolute cursor-pointer right-0 w-[30px] h-[30px] bg-white rounded-full grid items-center m-2"><p className="mx-auto">x</p></span>
              <p className="p-4">By using our services, you agree to Chgams Terms and Conditions <a className="underline" href="/terms-and-conditions">here.</a></p>
            </div>
          </div> : null
        }
    </div>
      
      
    </>
    
  )
}

export default HomePage