import React from 'react'
import Layout from '../../layout'

function GivingDetails() {
  return (
    <>
        <Layout props='givingdetails'></Layout>
    </>
  )
}

export default GivingDetails