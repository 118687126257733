import React from 'react'
import Layout from '../../layout'

function GivingTypes() {
  return (
    <>
        <Layout props='givingtypes'></Layout>
    </>
  )
}

export default GivingTypes