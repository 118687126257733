import {useState} from 'react'
import { Link } from "react-router-dom";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";



const Password = () => {
    const [pass, setPass] = useState();
    const [pass2, setPass2] = useState();
    const [pass3, setPass3] = useState();
  return (
    <div>
      <p className="text-[20px] font-[600] leading-normal text-[#121927]">
        Password Reset
      </p>
      <div className="w-full md:w-1/2 m-auto">
        <div className="mt-4">
          <div className="flex flex-row justify-between">
            <label className="text-[16px] font-[400] leading-normal text-[#505050]">
              Current Password
            </label>
          </div>
          <div className="w-full relative mt-2">
            <input
              name="password"
              type={pass2 ? "text" : "password"}
              className="border-[1px] rounded-[4px] w-full h-[50px] px-3 outline-none"
              placeholder="Input your password"
            />
            <span
              onClick={() => setPass(!pass)}
              className="absolute right-3 top-4 cursor-pointer "
            >
              {pass2 ? <AiOutlineEye /> : <AiOutlineEyeInvisible />}
            </span>
          </div>
        </div>
        <div className="mt-4">
          <div className="flex flex-row justify-between">
            <label className="text-[16px] font-[400] leading-normal text-[#505050]">
              New Password
            </label>
          </div>
          <div className="w-full relative mt-2">
            <input
              name="password"
              type={pass2 ? "text" : "password"}
              className="border-[1px] rounded-[4px] w-full h-[50px] px-3 outline-none"
              placeholder="Enter your password"
            />
            <span
              onClick={() => setPass2(!pass2)}
              className="absolute right-3 top-4 cursor-pointer "
            >
              {pass2 ? <AiOutlineEye /> : <AiOutlineEyeInvisible />}
            </span>
          </div>
        </div>
        <div className="mt-4">
          <div className="flex flex-row justify-between">
            <label className="text-[16px] font-[400] leading-normal text-[#505050]">
              Confirm New Password
            </label>
          </div>
          <div className="w-full relative mt-2">
            <input
              name="password"
              type={pass2 ? "text" : "password"}
              className="border-[1px] rounded-[4px] w-full h-[50px] px-3 outline-none"
              placeholder="Enter your new password"
            />
            <span
              onClick={() => setPass3(!pass3)}
              className="absolute right-3 top-4 cursor-pointer "
            >
              {pass2 ? <AiOutlineEye /> : <AiOutlineEyeInvisible />}
            </span>
          </div>
        </div>
        <div className="w-full pt-5">
          <button className="bg-[#B30000] text-white w-full h-[50px] rounded-[4px]">
            Reset Password
          </button>
        </div>
      </div>
    </div>
  );
}

export default Password