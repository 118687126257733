import React, {useState,useEffect} from 'react'
import give from "../../../images/icons/give.svg"
import download from "../../../images/icons/download.svg"
import filter from "../../../images/icons/filter.svg"
import search from "../../../images/icons/search.svg"
import calender from "../../../images/icons/calendar.svg"
import edit from "../../../images/icons/edit.svg"
import view from "../../../images/icons/view.svg"
import delet from "../../../images/icons/delete.svg"
import deleteRed from "../../../images/icons/deleteRed.svg"
import more from "../../../images/icons/more.svg"
import { ThreeDots } from 'react-loader-spinner'
import { Link } from 'react-router-dom'
import { CSVLink, CSVDownload } from "react-csv";
import axios from 'axios'
import { LineWave } from  'react-loader-spinner'
import settings from '../../../components/getrequest'
import { format } from 'date-fns';

function Report() {
  const [base, setBase] = useState(sessionStorage.getItem('prefix') ? sessionStorage.getItem('prefix'): 'user')
  let urlbase = process.env.REACT_APP_BASE;
  const donationsheaders = [
    { label: "id", key: "id" },
    { label: "name", key: "name" },
    { label: "date", key: "date" },
    { label: "member_id", key: "member_id" },
    { label: "amount", key: "amount" },
    { label: "type", key: "type" },
    { label: "payment", key: "payment" },
  ]
  const [donationsData, setDonationsData] = useState([])
const[fetching, setFetching] = useState(1)
const sliceValue = 4
const [givingArray, setgivingArray] = useState([])
const[givingCurrent, setgivingCurrent] = useState([])
const[givingCurrentDefault, setgivingCurrentDefault] = useState()
const [currentPage,setCurrentPage] = useState(0)
const [error, setError] = useState(0)
const [processing, setProcessing] = useState(0)
const [totalAmount, setTotalAmount] = useState(0)
useEffect(()=>{
    //get report 
    axios.get(urlbase+'/api/reports',settings())
    .then((res) => {
        if(res.data.code == 200){
          setTotalAmount(res.data.data.total_amount ? res.data.data.total_amount : 0)
          setDonationsData(res.data.data.givings)
          const allData = res.data.data.givings;
          const slicedData = [];
          for (let i = 0; i < allData.length; i += sliceValue) {
            slicedData.push(allData.slice(i, i + sliceValue));
          }
          setgivingCurrent(slicedData[0])
          setgivingArray(slicedData);
        }else{
            setError(res.data.message)
            setProcessing(0)
        }
    }).catch((e) => {
        setError('There was an internal error, please try again later')
        setProcessing(0)
    })

  setFetching(0)
},[])
const createPages = async () => {
  let L = donationsData.length
  let last_index = sliceValue
  if(donationsData.length > sliceValue) {
      for(L; L-sliceValue > 0; L-sliceValue){
          L = L-sliceValue;
          let new_last_index = last_index+sliceValue
          let NewObject = donationsData.slice(last_index,new_last_index)
          last_index = new_last_index
          if(NewObject.length > 0){
              givingArray.push(NewObject)
          } 
      }
      const updatedDonations = [...givingArray]
      setgivingArray(updatedDonations)
  }else{
      setgivingArray([donationsData])
  } 
}
const PreviousPage = () => {
  window.scrollTo({ top: 0, behavior: 'smooth' });
  setCurrentPage(currentPage > 0 ? currentPage-1 : currentPage)
  if(currentPage > 0){
    setgivingCurrent(givingArray[currentPage-1])
    setgivingCurrentDefault(givingArray[currentPage-1])
  }
}
const NextPage = () => {
  window.scrollTo({ top: 0, behavior: 'smooth' });
  setCurrentPage(currentPage == givingArray.length-1 ? currentPage : currentPage+1)
  if(currentPage == givingArray.length-1){

  }else{
    setgivingCurrent(givingArray[currentPage+1])
    setgivingCurrentDefault(givingArray[currentPage+1])
  }
}
const numberedPage = (id) => {
  window.scrollTo({ top: 0, behavior: 'smooth' });
  setCurrentPage(id)
  setgivingCurrent(givingArray[id])
  setgivingCurrentDefault(givingArray[id])
}
const [searchItem, setSearchItem] = useState("");
//Active donation
const [activeDonation, setActiveDonation] = useState()
const changeActiveDon = (id) => {
  if(activeDonation == id) setActiveDonation(null)
  else setActiveDonation(id)
}

//giving types
const givingTypes = ['Cash','Individual','Aggregated','GASDS']

//filter
const [filterr, setFilterr] = useState()
const filterAction = () => {
  if(filterr) setFilterr(0)
  else setFilterr(1)
}

//edit giving
const [givingModal, setGivingModal] = useState()
const setTheModal = () => {
  setActiveDonation(null)
  setGivingModal(1)
}

//delete donation
const [deleteModal, setDeleteModal] = useState()
const setTheDelete = () => {
  setActiveDonation(null)
  setDeleteModal(1)
}

//view details
const viewDetails = () => {
  window.location.href="/giving-details"
}
  return (
    <>
    {deleteModal ?
      <div id="defaultModal" tabindex="-1" aria-hidden="true" className="fixed top-0 left-0 right-0 z-50 w-full p-4 overflow-x-hidden overflow-y-auto md:inset-0 h-[calc(100%-1rem)] max-h-full">
          <div className="relative w-[50%] lg:w-[35%] mt-[10%] max-h-full mx-auto text-[#333]">
              <div className="relative bg-white shadow pt-4 rounded-[8px]">
                  <div className="flex items-start justify-between px-8 pb-4">
                      <h3 className="text-[#121927] text-[20px] font-[600]">
                      </h3>
                      <button onClick={() => setDeleteModal(0)} type="button" className="text-gray-300 font-[200] bg-[#F2F2F2] hover:text-black rounded-full p-1.5 ml-auto inline-flex items-center" data-modal-hide="defaultModal">
                          <svg aria-hidden="true" className="w-5 h-5 " fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clip-rule="evenodd"></path></svg>
                          <span className="sr-only">Close modal</span>
                      </button>
                  </div>
                  <div className='flex flex-col space-y-4 mt-3 px-5'>
                    <div className='grid mb-2'><img className='mx-auto' src={deleteRed} /></div>
                    <span className='text-[#121927] font-[600] text-[24px]'>Delete Giving Details</span>
                    <span className='text-[#4F4F4F] text-[16px]'>Deleted donation can not be retrieved. Are you sure you want to delete?</span>
                  </div>
                  <div className="flex flex-col mt-5 space-y-3 px-8 py-4">
                      <button data-modal-hide="defaultModal" type="button" className="border-[1px] w-full h-[50px] bg-[#EB5757] rounded-[8px] border-none text-white">Yes, delete donation</button>
                      <button onClick={() => setDeleteModal(0)} data-modal-hide="defaultModal" type="button" className="border-[#828282] w-full h-[50px] rounded-[8px] bg-[#F7F7F7] text-[#333333]">No, Cancel</button>
                  </div>
              </div>
          </div>
      </div>
      :null}

    {givingModal ?
      <div id="defaultModal" tabindex="-1" aria-hidden="true" className="fixed top-0 left-0 right-0 z-50 w-full p-4 overflow-x-hidden overflow-y-auto md:inset-0 h-[calc(100%-1rem)] max-h-full">
          <div className="relative w-[50%] lg:w-[40%] mt-[10%] max-h-full mx-auto text-[#333]">
              <div className="relative bg-white shadow pt-3">
                  <div className="flex items-start justify-between border-b-[1px] px-8 pb-3">
                      <h3 className="text-[#121927] text-[20px] font-[600]">
                          Edit Giving Details
                      </h3>
                      <button onClick={() => setGivingModal(0)} type="button" className="text-gray-300 font-[200] bg-transparent hover:bg-[#F2F2F2] hover:text-black rounded-full p-1.5 ml-auto inline-flex items-center" data-modal-hide="defaultModal">
                          <svg aria-hidden="true" className="w-5 h-5 " fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clip-rule="evenodd"></path></svg>
                          <span className="sr-only">Close modal</span>
                      </button>
                  </div>
                  <div className='flex flex-col space-y-7 mt-3'>
                    <div className="mt-5 grid flex flex-col text-start space-y-2 px-8">
                        <div className='text-[#505050]'>Member ID</div>
                        <input className='border-[1px] rounded-[8px] p-3 outline-none text-[#4F4F4F]' readOnly defaultValue='Merik2345GH' placeholder='member Id'/>
                    </div>
                    <div className="mt-5 grid flex flex-col text-start space-y-2 px-8">
                        <div className='text-[#505050]'>Member Name</div>
                        <input className='border-[1px] rounded-[8px] p-3 outline-none text-[#4F4F4F]' readOnly defaultValue='Israel Akinola' placeholder='member Id'/>
                    </div>
                  </div>
                  <div className='grid grid-cols-2 gap-x-5 gap-y-2 mt-3 px-8'>
                    <div className="col-span-1 mt-5 grid flex flex-col text-start space-y-2">
                        <div className='text-[#505050]'>Giving Type</div>
                        <select className='border-[1px] rounded-[8px] p-3 outline-none text-[#4F4F4F] w-[100%]'>
                          <option>Individual</option>
                        </select>
                    </div>
                    <div className="col-span-1 mt-5 grid flex flex-col text-start space-y-2">
                        <div className='text-[#505050]'>Payment method</div>
                        <select className='border-[1px] rounded-[8px] p-3 outline-none text-[#4F4F4F] w-[100%]'>
                          <option>Bank</option>
                        </select>
                    </div>
                    <div className="col-span-1 mt-5 grid flex flex-col text-start space-y-2">
                        <div className='text-[#505050]'>Amount (£)</div>
                        <input className='border-[1px] rounded-[8px] p-3 outline-none text-[#4F4F4F]' defaultValue='1200000' placeholder='member Id'/>
                    </div>
                    <div className="col-span-1 mt-5 grid flex flex-col text-start space-y-2">
                        <div className='text-[#505050]'>Payment date</div>
                        <input type='date' className='border-[1px] rounded-[8px] p-3 outline-none text-[#4F4F4F]' defaultValue='Israel Akinola' placeholder='member Id'/>
                    </div>
                    <div className="col-span-2 mt-5 grid flex flex-col text-start space-y-2">
                        <div className='text-[#505050]'>Comment</div>
                        <textarea className='border-[1px] rounded-[8px] p-3 outline-none text-[#4F4F4F] h-[118px]' placeholder='Type comment here'></textarea>
                    </div>
                  </div>
                  <div className="flex flex-row justify-end mt-5 space-x-2 px-8 bg-[#F7F7F7] py-4">
                      <button onClick={() => setGivingModal(0)} data-modal-hide="defaultModal" type="button" className="border-[1px] border-[#828282] w-[102px] h-[50px] rounded-[8px]">Cancel</button>
                      <button data-modal-hide="defaultModal" type="button" className="border-[1px] w-[178px] h-[50px] bg-[#B30000] rounded-[8px] border-none text-white">Update Changes</button>
                  </div>
              </div>
          </div>
      </div>
      :null}

      <div className={`grid grid-cols-1 mt-5 mb-5 px-8 ${givingModal ? 'opacity-25' : ''}`}>
        <div className='col-span-1 flex lg:flex-row flex-col justify-between space-y-4 lg:space-y-0'>
          <p className='text-[#292D32] text-[24px] font-[600] text-start'>Report</p>
          <div className='flex flex-row space-x-4'>
          <CSVLink filename={"donations.csv"} data={donationsData} headers={donationsheaders}>
            <div className='border-[1px]  h-[40px] px-3 cursor-pointer rounded-[4px] flex flex-row p-2 items-center space-x-3'>
              <img src={download} />
              <p className='text-[#292D32] text-[15px] font-[600]'>Download CSV</p>
            </div>
            </CSVLink>
          </div>
        </div>
        <div className='col-span-1 flex lg:flex-row flex-col space-y-4 lg:space-y-0 justify-between mt-8 lg:items-center text-start'>
          <div className='relative flex flex-col'>
            <span className='text-[#8A8A8A] font-[400] text-[14px]'>Total Amount</span>
            <span className='text-[#121927] font-[600] text-[28px]'>£{parseInt(totalAmount).toLocaleString()}</span>
          </div>
          <div className='flex lg:flex-row flex-col space-y-4 lg:space-y-0  lg:space-x-4 lg:items-center'>
            <div className='relative'>
              <div className='absolute top-3 z-50 left-4'><img src={search} /></div>
              <input onChange={(e) => setSearchItem(e.target.value)} className='relative w-[321px] bg-[#F7F7F7] h-[40px] pl-10 rounded-[8px] border-[1px] text-[#8A8A8A] outline-none' placeholder='Search' />
            </div>
            <div className=''>
              <input className='relative border-[1px] h-[40px] w-full lg:w-auto rounded-[4px] outline-none border-[#D9D9D9] p-1' type='date' />
            </div>
            <div>
              <div onClick={() => filterAction()} className='flex flex-row space-x-3 border-[1px] rounded-[4px] p-1 px-3 h-[40px] cursor-pointer'>
                <img src={filter} />  
                <span>Filter</span>
              </div>
              {filterr ?
              <div className='absolute rounded-[4px] flex flex-col z-50 bg-white space-y-3 shadow-md right-10 py-2'>
                {givingTypes.map((item,id) => {
                  return (
                    <div className='flex flex-row space-x-2 px-4 cursor-pointer hover:bg-[#F7F7F7] py-2'>
                      <input type='checkbox' className='accent-[#B30000]'/>
                      <p>{item}</p>
                    </div>
                  )
                })}
              </div>
              : null}
            </div>
          </div>
        </div>
        
        {donationsData.length > 0 ? <>
        <div className='col-span-1 mt-5 border-[1px] rounded-[8px]'>
          <table className="table-auto overflow-x-scroll w-full block divide-y divide-gray-200 ">
            <thead className='bg-[#F7F7F7]'>
                <tr className=''>
                <th className='px-6 py-1 text-start text-[#292D32] font-[400] w-[14.29%]'>S/N</th>
                <th className='px-6 py-1 text-start text-[#292D32] font-[400] w-[14.29%]'>Donor Name</th>
                <th className='px-6 py-1 text-start text-[#292D32] font-[400] w-[14.29%]'>Date & Time</th>
                <th className='px-6 py-1 text-start text-[#292D32] font-[400] w-[14.29%]'>Member ID</th>
                <th className='px-6 py-1 text-start text-[#292D32] font-[400] w-[14.29%]'>Amount</th>
                <th className='px-6 py-1 text-start text-[#292D32] font-[400] w-[14.29%]'>Giving Type</th>
                <th className='px-6 py-1 py-1 text-start text-[#292D32] font-[400] w-[14.29%]'>Payment method</th>
                </tr>
            </thead>
            <tbody className='divide-y divide-gray-200'>
                {givingCurrent.filter((row) =>
                !searchItem.length || row.name
                .toString()
                .toLowerCase()
                .includes(searchItem.toString().toLowerCase()) 
                ).map(({id,name,member_id,payment_date,amount,type,payment_method},index) => {
                    return (
                        <tr key={id}>
                            <td className='px-6 py-3 text-start'>{index+1}</td>
                            <td className='px-6 py-3 text-start text-[#4F4F4F]'>{name}</td>
                            <td className='px-6 py-3 text-start text-[#4F4F4F]'>{payment_date}</td>
                            <td className='px-6 py-3 text-start text-[#4F4F4F]'>M{member_id}</td>
                            <td className='px-6 py-3 text-start text-[#4F4F4F]'>£{amount.toLocaleString()}</td>
                            <td className='px-6 py-3 text-start text-[#4F4F4F]'>{type == 1 ? 'Individual(Offering)' : type == 2 ? 'Individual(Tithe)': type==3 ? 'GASD' : 'Aggregated'}</td>
                            <td className='px-6 pr-3 py-3 text-start text-[#4F4F4F]'>{payment_method}</td>
                        </tr>
                        )
                    }) 
                }
            </tbody>
          </table>
        </div>
        <div className='col-span-1 flex flex-row justify-between items-center'>
          {searchItem? '' : <span className='text-[#333333] text-[16px]'>Showing <span>{givingCurrent.length}</span>/<span>{donationsData.length}</span> results</span>}
          {fetching ? 
            <div className='col-span-8 lg:col-span-6 flex flex-col justify-center items-center '>
                <ThreeDots height="80" width="80" radius="9" color="#333333" ariaLabel="three-dots-loading"
                wrapperStyle={{}} wrapperClassName="" visible={true}
                />
            </div> 
            :
          <div className={`ml-auto grid grid-cols-6 gap-x-4`}>
                <div className={`mt-5 col-span-6 flex flex-row gap-x-3 mb-5 lg:ml-auto`}>
                    <div onClick={PreviousPage} className={`border-[#BCBCBC] h-[40px] lg:h-auto max-h-[46px] w-[35px] lg:w-auto border-[1px] px-2 lg:px-4 py-1 lg:py-2 rounded-[6px] items-center text-[#BCBCBC] hover:text-[#1D242D] cursor-pointer`}>{`<`}</div>
                    {givingArray.slice(0,5).map((givingArray,index)=> {
                        return (
                            <div key={index} onClick={()=>numberedPage(index)} className={`transitiion-all h-[40px] lg:h-auto max-h-[46px] w-[35px] lg:w-auto text-start duration-500 border-[#BCBCBC] font-[600] text-[18px] border-[1px] px-2 lg:px-4 py-1 lg:py-2 rounded-[6px] items-center ${currentPage == index ? 'text-white bg-[#B30000] border-[#B30000] hover:bg-[#B30000] hover:text-white' :'text-[#292D32] bg-white hover:text-white hover:bg-[#B30000]'} hover:text-[#1D242D] cursor-pointer`}>{index+1}</div>
                        )
                    })}
                    <div onClick={NextPage} className={`border-[#BCBCBC] border-[1px] lg:h-auto max-h-[46px] w-[35px] lg:w-auto px-2 lg:px-4 py-1 lg:py-2 rounded-[6px] items-center text-[#BCBCBC] hover:text-[#1D242D] cursor-pointer`}>{`>`}</div>
                </div>
            </div>
          }
        </div>
          </> : <div>No donations have been made yet</div> }

      </div>
    </>
  )
}

export default Report