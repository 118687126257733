import React, { useEffect, useState } from 'react'
import back from "../../../images/icons/back.svg"
import upload from "../../../images/icons/upload.svg"
import file from "../../../images/icons/file.svg"
import delet from "../../../images/icons/delete.svg"
import remove from "../../../images/icons/remove.svg"
import Papa from "papaparse"
import { Link } from 'react-router-dom'
import success from "../../../images/success.svg"
import axios from 'axios'
import { LineWave } from  'react-loader-spinner'
import settings from '../../../components/getrequest'
import * as XLSX from 'xlsx';
import { format } from 'date-fns';

function AddGiving() {
    const [base, setBase] = useState(sessionStorage.getItem('prefix') ? sessionStorage.getItem('prefix'): 'user')
    let urlbase = process.env.REACT_APP_BASE;
    const [givingadded, setGivingAdded] = useState()
    const processGiving = () => {
        setGivingAdded(1)
      }
    const InputGiving = () => { 
        const [givingData, setGivingData] = useState({})
        const [givingType, setGivingType] = useState()
        const [makeCheck, setMakeCheck] = useState()
        const AddCheckMember = (field,value) => {
            setGivingData({...givingData, [field]:value})
            if(givingData.member_id){
                setMakeCheck(1)
            }else{

            }
        }
        const AddGivingData = (field,value) => {
            setGivingData({...givingData, [field]:value})
          }
        const [addingProcessing,setAddingProcessing] = useState()
        const [addingError,setAddingError] = useState()
        const PerformAdd = (e) => {
            if(givingData.type == null || givingData.type == 'null'){
                alert('Please choose giving type')
                return 0;
            }
            if(givingData.payment_method == null || givingData.payment_method == 'null'){
                alert('Please choose payment method')
                return;
            }
            if(givingData.type == 4 || givingData.type == 5){
                if(!givingData.comment){
                    alert('Comment is required for GASDS, Aggregated and Group donations')
                    return;
                }
            }
            if(isGroup){
                if(givingData.group == null || givingData.group == 'null'){
                    alert('Please choose a group')
                    return;
                }
            }else{
                givingData.group = 0
            }
            setAddingProcessing(1)
            e.preventDefault();
            axios.post(urlbase+'/api/givings/create',givingData,settings())
            .then((res) => {
                if(res.data.code == 200){
                    setGivingAdded(1)
                }else{
                    alert(res.data.message);
                }
                setAddingProcessing(0)
            }).catch(() => {
                //setAddingError('There was an error, please try again')
                alert('There was an error, please try again');
                setAddingProcessing(0)
            })
        }
        const [memberName, setMemberName] = useState()
        const CheckMember = () => {
            setAddingProcessing(1)
            axios.get(urlbase+'/api/members/check?id='+givingData.member_id,settings())
            .then((res) => {
                if(res.data.code == 200){
                    setMemberName(res.data.data.name)
                }else{
                    setMemberName('')
                    alert(res.data.message);
                }
                setAddingProcessing(0)
            }).catch(() => {
                //setAddingError('There was an error, please try again')
                alert('There was an error, please try again');
                setAddingProcessing(0)
            })
        }
        const today = new Date().toISOString().split('T')[0];
        const [gasdCheck, setGasdCheck] = useState(false)
        const [isGroup, setIsGroup] = useState(false)
        const [groupsData,setgroupsData] = useState([])
        const [otherType, setOtherType] = useState();
        const AddCheckGivingType = (val) => { 
            if(val == 3) setOtherType(1)
            else setOtherType(0)
            if(val == 6) {
                AddGivingData('type',5); 
                setIsGroup(true)
            }
            else {
                AddGivingData('type',val)
                setIsGroup(false)
            }
            if(val == 4 || val == 5 || val == 6) setGasdCheck(true)
            else setGasdCheck(false)
        }
        const getGroups = async (url) => {
            axios.get(urlbase+url, settings())
              .then((res) => {
                if (res.data.code === 200) {
                  if(res.data.data.length > 0){
                    setgroupsData(res.data.data)
                  }else{
                    setgroupsData([])
                  }
                } else {
                    console.log(res.data.message);
                }
              })
              .catch((e) => {
                console.log('There was an internal error, please try again later');
              });
          }
          const [givingTypes, setGivingTypes] = useState([])
          const getGivingTypes = async (url) => {
            axios.get(urlbase+url, settings())
              .then((res) => {
                if (res.data.code === 200) {
                  if(res.data.data.length > 0){
                    setGivingTypes(res.data.data)
                  }else{
                    setGivingTypes([])
                  }
                } else {
                    console.log(res.data.message);
                }
              })
              .catch((e) => {
                console.log('There was an internal error, please try again later');
              });
          }
          useEffect(() => { 
            getGroups('/api/groups')
            getGivingTypes('/api/givings/types')
          },[])
        return (
            <> 
                <form className='grid grid-cols-6 gap-x-3 gap-y-6 mb-10'>
                    <div className={`col-span-6 lg:col-span-2 flex flex-col space-y-2 text-[#505050]`}>
                        <label className=''>Giving type</label>
                        <select onChange={(e) => AddCheckGivingType(e.target.value)} className='rounded-[8px] border-[1px] bg-white  p-3 outline-none h-[50px] '>
                            <option selected className='text-[#9A9A9A] bg-white' value='null'>Select giving type</option>
                            <option className='bg-white' value={1}>Individual(Offering)</option>
                            <option className='bg-white' value={2}>Individual(Tithe)</option>
                            <option className='bg-white' value={3}>Individual(Others)</option>
                            <option className='bg-white' value={4}>GASDS</option>
                            <option className='bg-white' value={5}>Aggregated</option>
                            <option className='bg-white' value={6}>Group</option>
                        </select>
                    </div>
                    {
                        otherType ? 
                        <div className='col-span-6 lg:col-span-2 flex flex-col space-y-2 text-[#505050]'>
                            <label className=''>Individual(Others) - Optional</label>
                            <select onChange={(e) => AddGivingData('org_type',e.target.value)} className='rounded-[8px] border-[1px] bg-white  p-3 outline-none h-[50px] '>
                                <option selected className='text-[#9A9A9A] bg-white' value='null'>Streamline giving type</option>
                                {givingTypes.map(({id,type}) => {
                                    return (
                                        <option className='bg-white' value={id}>{type}</option>
                                    )
                                })}
                            </select>
                            <span className='text-[#B30000] text-[12px]'>Create a custom Individual giving type <Link to={'/'+base+'/giving-types'} className='underline'>here</Link></span>
                        </div> : null
                    }
                    {isGroup ? <div className={`col-span-6 lg:col-span-2 flex flex-col space-y-2 text-[#505050]`}>
                        <label className=''>Group</label>
                        <select onChange={(e) => AddGivingData('group',e.target.value)} className='rounded-[8px] border-[1px] bg-white  p-3 outline-none h-[50px] '>
                            <option selected className='text-[#9A9A9A] bg-white' value='null'>Select group</option>
                            {groupsData.map(({id,name}) => {
                                return (
                                    <option key={id} className='bg-white' value={id}>{name}</option>
                                )
                            })}
                        </select>
                    </div> : null }
                    <div className={`col-span-6 ${gasdCheck ? 'hidden' : ''} lg:col-span-2 flex flex-col space-y-2 text-[#505050]`}>
                        <div className='flex flex-row space-x-2 items-center'>
                            <label className=''>Member ID </label>
                            <label className='cursor-pointer' title='This is not required unknown donors'>
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#B30000" class="bi bi-info-circle" viewBox="0 0 16 16">
                                <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                                <path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z"/>
                                </svg>
                            </label>
                        </div>

                        <div className='flex flex-col'>
                            <input required onChange={(e) => AddCheckMember('member_id',e.target.value)} className='rounded-[8px] border-[1px] p-3 outline-none' placeholder='Enter member’s ID'></input>
                            {makeCheck ?
                                <label className='text-[14px] text-[#B30000] text-right cursor-pointer' onClick={CheckMember}>Check member now</label>
                                : ''
                            }
                        </div>

                    </div>
                    <div className={`col-span-6 ${gasdCheck ? 'hidden' : ''} lg:col-span-2 flex flex-col space-y-2 text-[#505050]`}>
                        <label className=''>Member name</label>
                        <input className='rounded-[8px] border-[1px] p-3 outline-none' readOnly defaultValue={memberName}></input>
                    </div>
                    <div className='col-span-6 lg:col-span-2 flex flex-col space-y-2'>
                        <label className='text-[#9A9A9A]'>Entry officer</label>
                        <input className='rounded-[8px] border-[1px] p-3 border-[#EAEAEA] text-[#9A9A9A] outline-none' readOnly defaultValue={sessionStorage.getItem('user_name')}></input>
                    </div>
                    <div className='col-span-6 lg:col-span-2 flex flex-col space-y-2 text-[#505050]'>
                        <label className=''>Amount</label>
                        <input onChange={(e) => AddGivingData('amount',e.target.value)} className='rounded-[8px] border-[1px] p-3 outline-none' placeholder='Enter amount' type='number'></input>
                    </div>
                    <div className='col-span-6 lg:col-span-2 flex flex-col space-y-2 text-[#505050]'>
                        <label className=''>Payment method</label>
                        <select onChange={(e) => AddGivingData('payment_method',e.target.value)} className='rounded-[8px] border-[1px] bg-white  p-3 outline-none h-[50px] '>
                            <option selected className='text-[#9A9A9A] bg-white' value='null'>Select payment method</option>
                            ${gasdCheck ? null : <option className='bg-white' value='Bank Transfer'>Bank Transfer</option>}
                            <option className='bg-white' value='Cash'>Cash</option>
                            <option className='bg-white' value='POS'>POS</option>
                        </select>
                    </div>
                    <div className='col-span-6 lg:col-span-2 flex flex-col space-y-2 text-[#505050]'>
                        <label className=''>Payment Date</label>
                        <input max={today} onChange={(e) => AddGivingData('payment_date',e.target.value)} type='date' className='rounded-[8px] border-[1px] p-3 outline-none' placeholder='Enter amount'></input>
                    </div>
                    <div className='col-span-6 flex flex-col space-y-2 text-[#505050]'>
                        <label className=''>Comment</label>
                        <textarea onChange={(e) => AddGivingData('comment',e.target.value)} type='text' className='rounded-[8px] border-[1px] p-3 outline-none h-[114px]' placeholder='Type comment here...'></textarea>
                    </div>
                    <div className='lg:col-span-6 flex flex-col space-y-2 text-[#505050]'>
                        {addingProcessing ? <LineWave height="100" width="100" color="#B30000" ariaLabel="line-wave" wrapperStyle={{marginTop:'-40px'}}
                      wrapperClass="" visible={true} firstLineColor="" middleLineColor="" lastLineColor=""/>
                     :
                        <button type='button' onClick={PerformAdd} className='bg-[#B30000] w-[142px] h-[50px] text-[18px] text-white rounded-[4px] font-[600]'>Add Giving</button>
                        }
                    </div>
                </form>
            </>
        )
    }
    
    const UploadGiving = () => {
        const [error, setError] = useState()
        const [fileChosen, setFileChosen] = useState()
        const [theFile, setTheFile] = useState({})
        const [data, dataSet] = useState()
        const [uploadedData, setUploadedData] = useState([])
        const fileChange = (e) => {
            if(!e.target.files[0]){
                setFileChosen(0)
                document.getElementById('file').value=null
                return
            }
            let file = e.target.files[0]
            if (file.type != 'text/csv' && !['application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', 'application/vnd.ms-excel'].includes(file.type)) {
                setFileChosen(0)
                document.getElementById('file').value=null
                setError('Invalid file type')
            }else{
                setTheFile(file)
                setFileChosen(1)
                setError(null)
                parseCSV(e)
            }
        }
        const selectFile = () => {
            document.getElementById('file').click()
        }
        const [addingProcessing,setAddingProcessing] = useState()
        const removeFile = () => {
            document.getElementById('file').value=null
            dataSet(0)
            setTheFile(null)
            setFileChosen(0)
            setError(null)
        }

        const parseCSV = (e) => {
            const file = e.target.files[0];
        
            // Check if a file is selected
            if (!file) {
                return;
            }
        
            const reader = new FileReader();
        
            reader.onload = function (e) {
                const data = e.target.result;
        
                // Check if the file type is either CSV or Excel
                if (file.type === 'text/csv' || file.type === 'application/vnd.ms-excel' || file.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
                    // Parse CSV or Excel based on file type
                    if (file.type === 'text/csv' || file.name.endsWith('.csv')) {
                        Papa.parse(data, {
                            header: true,
                            skipEmptyLines: true,
                            complete: function (results) {
                                setUploadedData(results.data);
                            },
                        });
                    } else {
                        const workbook = XLSX.read(data, { type: 'binary' });

                        const sheetName = workbook.SheetNames[0];
                        const sheetData = XLSX.utils.sheet_to_json(workbook.Sheets[sheetName], {
                            raw: false,
                            dateNF: 'yyyy-mm-dd',
                            cellDates: true,
                            header: 1, // Assuming the first row contains headers
                        });
                
                        // Check if headers are present in the first row
                        const hasHeaders = sheetData.length > 0 && Object.keys(sheetData[0]).length > 0;

                        // Transform Excel data into an array of objects
                        const transformedData = (hasHeaders ? sheetData.slice(1) : sheetData).map((row) => {
                            const rowData = {};

                            // Define the column indices
                            const columnIndex = {
                                name: 0,
                                id: 1,
                                amount: 2,
                                type: 3,
                                method: 4,
                                date: 5,
                            };

                            // Fill in the column data for each row
                            Object.keys(columnIndex).forEach((column) => {
                                const colIndex = columnIndex[column];
                                rowData[column] = row[colIndex] !== undefined && row[colIndex] !== null ? String(row[colIndex]).trim() : '';
                            });

                            return rowData;
                        });

                        setUploadedData(transformedData);
                    }
                } else {
                    // Handle invalid file type
                    setUploadedData([]);
                    setError('Invalid file type');
                }
            };
        
            reader.readAsBinaryString(file);
        };
        
        const formatDate = (date) => {
            return date instanceof Date && !isNaN(date)
                ? date.toLocaleDateString('en-US', { year: 'numeric', month: '2-digit', day: '2-digit' })
                : date;
        };
    
          const removeGiving = () => {
            dataSet(0)
            setTheFile(null)
            setFileChosen(0)
            setError(null)
          }

          function isValidDateFormat(inputString) {
            const dateFormatRegex = /^\d{4}-(0?[1-9]|1[0-2])-(0?[1-9]|[12]\d|3[01])$/;
            return dateFormatRegex.test(inputString);
          }

          const processGiving = () => {
            try{
                let inputed = uploadedData;
                let err = 0;
                let count = 0;
                for (let i = 0; i < inputed.length; i++) {
                    const e = inputed[i];
                
                    if (!e.id || !e.amount || !e.type || !e.method || !e.date) {
                        alert("Please make sure all your data have 'id', 'amount', 'type', 'method', and 'date'. Also ensure there is no empty rows in your data");
                        err += 1;
                        break;  
                    }
                
                    if (!isValidDateFormat(e.date)) {
                        alert("Please make sure the date format in your data is yyyy-mm-dd e.g., 2023-10-21");
                        err += 1;
                        break;  
                    }
                
                    count += 1;
                }

                if(err > 0){
                    return;
                }
                if(count > 500){
                    alert('Sorry, you cannot upload more than 500 records at once');
                    return;
                }
                setAddingProcessing(1)

                let allUploaded = 0;
                const uploadPromises = [];
                inputed.forEach(e => {
                    let newGiving = e.id ? {
                        member_id: e.id,
                        amount: e.amount,
                        type: e.type == "Offering" ? 1 : e.type == "Tithe" ? 2 : 3,
                        payment_method: e.method,
                        payment_date: e.date
                    } : {
                        amount: e.amount,
                        type: e.type == "Offering" ? 1 : e.type == "Tithe" ? 2 : 3,
                        payment_method: e.method,
                        payment_date: e.date
                    };
                    allUploaded+=1
                    uploadPromises.push(newGiving);
                });

                // Wait for all promises to resolve
                Promise.all(uploadPromises)
                    .then(() => {
                        if (allUploaded > 0) {
                            axios.post(urlbase + '/api/givings/create/mass', {file_name:theFile.name,records:uploadPromises}, settings())
                            .then((res) => {
                                if (res.data.code == 200) {
                                    //  setGivingAdded(1)
                                    setAddingProcessing(0);
                                     if (res.data.data.errors) {
                                        alert(res.data.data.count + ' records were uploaded successfully but with some errors: '+res.data.data.errors);
                                        window.location.reload();
                                    }else{
                                        alert(res.data.data.count + ' records were uploaded successfully');
                                        window.location.reload();
                                    }
                                } else {
                                    setAddingProcessing(0);
                                    alert(res.data.message)
                                }
                            })
                            .catch(() => {
                                setAddingProcessing(0);
                                console.log('There was an error, please try again later');
                            });

                        } else {
                            setAddingProcessing(0);
                            alert('No uploads could be completed, please ensure you are using the correct format');
                            setAddingProcessing(0);
                        }
                    })
                    .catch(error => {
                        console.error('Error uploading records:', error);
                        alert('An error occurred during upload. Please check your network connection and try again.');
                        setAddingProcessing(0);
                    });

            }catch(e){
                alert('There was an error, please ensure your upload data is correct')
            }
          }
    
        return (
            <>
               {!data ?  
               <>
                <div><p>Download a sample format <Link target='_blank' to="/GivingsDataFormatForUpload.csv" download className='text-[#B30000] cursor-pointer'>here.</Link></p></div>
                <form className='grid grid-cols-6 gap-x-3 gap-y-6 mb-10 mt-2'>
                    <div onClick={selectFile} className='col-span-6 flex flex-col space-y-2 text-[#505050] border-dashed border-[1px] flex flex-col items-center py-10 rounded-[8px] cursor-pointer'>
                        <span><img src={upload} /></span>
                        <span className='text-[16px]'>Upload your file (Max of 500 records) <span className='text-[#B30000]'>here</span></span>
                        <span className='text-[#828282] text-[14px]'>CSV or Excel Files only</span>
                    </div>
                    {fileChosen ? <div className='col-span-6 rounded-[8px] border-[1px] flex flex-row p-3 items-center justify-between'>
                        <div className='flex flex-row items-center space-x-2'>
                            <span><img src={file} /></span>
                            <span>{theFile.name}</span>
                            {/* <span>{parseInt(theFile.size)} mb</span> */}
                        </div>
                        <span onClick={removeFile} title='remove' className='cursor-pointer'><img src={remove} /></span>
                    </div> : null}
                    {error ? <span className='text-[14px] text-[#B30000]'>{error}</span> : null}
                    <input accept='.csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel' onChange={fileChange} id="file" hidden type='file' className='' />
                    <div className='lg:col-span-6 flex flex-col space-y-2 text-[#505050]'>
                        <button onClick={() => dataSet(1)} disabled={!fileChosen ? true : false} className={`${fileChosen ? 'bg-[#B30000]' : 'bg-[#E0E0E0]'} w-[142px] h-[50px] text-[18px] text-white rounded-[4px] font-[600]`}>Continue</button>
                    </div>
                </form>
               </>
                : <div className='grid grid-cols-1'>
                    <div className='col-span-1 text-start'>{theFile.name}</div>
                <div className='col-span-1 border-[1px] rounded-[8px] mt-3'>
                  <table className="table-auto overflow-x-scroll w-full block divide-y divide-gray-200 ">
                    <thead className='bg-[#F7F7F7]'>
                        <tr className='px-7 py-1 text-[#292D32] font-[400]'>
                        <th className='pl-3 px-3 py-1 text-start text-[#292D32] font-[400] w-[14.2857143%]'>Donor Name</th>
                        <th className='px-3 py-1 text-start text-[#292D32] font-[400] w-[14.2857143%]'>Member ID</th>
                        <th className='px-3 py-1 text-start text-[#292D32] font-[400] w-[14.2857143%]'>Amount</th>
                        <th className='px-3 py-1 text-start text-[#292D32] font-[400] w-[14.2857143%]'>Giving Type</th>
                        <th className='px-3 py-1 text-start text-[#292D32] font-[400] w-[14.2857143%]'>Payment method</th>
                        <th className='px-3 py-1 text-start text-[#292D32] font-[400] w-[14.2857143%]'>Payment date</th>
                        <th className='px-3 py-1 text-start text-[#292D32] font-[400] w-[14.2857143%]'></th>
                        </tr>
                    </thead>
                    <tbody className='divide-y divide-gray-200'>
                        {uploadedData.map(({id,name,member_id,date,amount,type,method},index) => {
                            return (
                                <tr key={index}>
                                    <td className='pl-3 px-3 py-3 text-start text-[#4F4F4F]'>{name}</td>
                                    <td className='px-3 py-3 text-start text-[#4F4F4F]'>{id}</td>
                                    <td className='px-3 py-3 text-start text-[#4F4F4F]'>£{parseInt(amount).toLocaleString()}</td>
                                    <td className='px-3 py-3 text-start text-[#4F4F4F]'>{type}</td>
                                    <td className='px-3 py-3 text-start text-[#4F4F4F]'>{method}</td>
                                    <td className='px-3 py-3 text-start text-[#4F4F4F]'>{date}</td>
                                    <td className='px-3 py-3 text-start text-[#4F4F4F]'>
                                    </td>
                                </tr>
                                )
                            }) 
                        }
                    </tbody>
                </table>
                </div>
                <div className='col-span-1 flex flex-col space-y-2 text-[#505050] mt-6'>
                    {addingProcessing ? 'Please wait' : 
                    <div className='flex flex-row space-x-3 mb-5'>
                        <button onClick={() => processGiving()} className={`bg-[#B30000] w-[142px] h-[50px] text-[18px] text-white rounded-[4px] font-[600]`}>Add Giving</button>
                        <button onClick={() => dataSet(!data)} className={`bg-white border-[1px] border-[#B30000] w-[142px] h-[50px] text-[18px] text-black rounded-[4px] font-[600]`}>Cancel</button>
                    </div>

                    }
                </div>
                </div>
                }
            </>
        )
    }

const [givingUpdate,setGivingUpdate] = useState({})
function addUpdateField(field,value){
    setGivingUpdate({...givingUpdate,[field]:value})
}
const [processing, setProcessing] = useState(0)
const [updateProcessing, setUpdateProcessing] = useState()
const [error, setError] = useState(0)
const [givingInfo, setGivingInfo] = useState({})
const today = new Date().toISOString().split('T')[0];
const [pdate,setPdate] = useState()
function updateGiving(){
    setUpdateProcessing(1)
    axios.post(urlbase+'/api/givings/update?id='+sessionStorage.getItem('giving_id'),givingUpdate,settings())
    .then((res) => {
      if (res.data.code === 200) {
        setGivingModal(0)
        alert('Giving updated successfully')
        window.location.reload()
      } else {
        setError(res.data.message);
        setProcessing(0);
      }
      setUpdateProcessing(0)
    })
    .catch((e) => {
      setError('There was an internal error, please try again later');
      setProcessing(0);
    });
}
const [givingModal, setGivingModal] = useState()
const setTheModal = (id) => {
  // setGivingModal(1)
  sessionStorage.setItem('giving_id',id)
}
//change tabs
const [tab, setTab] = useState(1)
const changeTab = (id) => {
    setTab(id)
}
  return (
    <>    {givingadded ?
        <div id="defaultModal" tabindex="-1" aria-hidden="true" className="fixed top-0 left-0 right-0 h-screen bg-black bg-opacity-50 inset-0 items-center justify-center z-50 w-full p-4 overflow-x-hidden overflow-y-auto md:inset-0 h-[calc(100%-1rem)] max-h-full">
            <div className="relative w-[50%] lg:w-[48%] mt-[4%] max-h-full mx-auto text-[#333]">
                <div className="relative bg-white shadow pt-4 rounded-[8px] px-8 text-center">
                    <div className="flex items-start justify-between px-8">
                        <h3 className="text-[#121927] text-[20px] font-[600]">
                        </h3>
                    </div>
                    <div className='flex flex-col space-y-2 mt-3 px-5'>
                      <div className='grid mb-2'><img className='mx-auto w-[25%]' src={success} /></div>
                      <span className='text-[#121927] font-[600] text-[24px]'>Awesome! Donation has been successfully added</span>
                      <span className='text-[#4F4F4F] text-[16px]'>All members' donation can be found on the members page. check to see the specifics of each member's contribution.</span>
                    </div>
                    <div className="flex flex-row mt-5 space-x-3 px-8 pb-4 justify-center">
                        <Link to={'/'+base+'/giving'} data-modal-hide="defaultModal" type="button" className="border-[1px] w-full h-[50px] bg-white rounded-[8px] border-[1px] text-[#121927] grid my-auto text-center"><p className="my-auto">View Donations</p></Link>
                        <Link onClick={() => setGivingAdded(0)} data-modal-hide="defaultModal" type="button" className="border-[#828282] w-full h-[50px] rounded-[8px] bg-[#B30000] text-white grid text-center"><p className="my-auto">Add another donation</p></Link>
                    </div>
                </div>
            </div>
        </div>
        :null}

      <div className='grid grid-cols-1 mt-5 text-start px-8'>
          <Link to={'/'+base+'/giving'} className='col-span-1 flex flex-row space-x-3'>
              <img src={back}/>
              <span className='text-[#4F4F4F] text-[16px]'>Back</span>
          </Link>
          <p className='text-[#121927] font-[600] text-[24px] mt-5'>Add giving</p>
          <div className='flex flex-row border-b-[1px] mt-10 space-x-6'>
            <span onClick={() => changeTab(1)} className={`${tab == 1 ? 'border-b-[1px] border-[#B30000] text-[#B30000]' : ''} px-2 pb-1 cursor-pointer`}>Input giving</span>
            {sessionStorage.getItem('role') !=1 && sessionStorage.getItem('role') !=2 ? null : sessionStorage.getItem('chgamsSubscription') == 4 ||  sessionStorage.getItem('chgamsSubscription') ==5 ?<span onClick={() => changeTab(2)} className={`${tab == 2 ? 'border-b-[1px] border-[#B30000] text-[#B30000]' : ''} px-2 pb-1 cursor-pointer`}>Upload giving</span> : null}
          </div>
          <div className='mt-10'>
            {tab == 1 ? <InputGiving></InputGiving> : <UploadGiving></UploadGiving>}
          </div>
       </div>
    </>
  )


}

export default AddGiving