import React,{useState,useEffect} from 'react'
import axios from 'axios'
import settings from '../../../components/getrequest'
import search from "../../../images/icons/search.svg"

function Letters() {
  const [base, setBase] = useState(sessionStorage.getItem('prefix') ? sessionStorage.getItem('prefix'): 'user')
  let urlbase = process.env.REACT_APP_BASE;
  //add participant 
  const [members, setMembers] = useState([])
  const [error, setError] = useState(0)
  const [processing, setProcessing] = useState(0)
  const [memberData, setMemberData] = useState()

const [periods, setPeriods] = useState([])
const allPeriods = async () => {
axios
    .get(urlbase + "/api/settings/periods", settings())
    .then((res) => {
    if (res.data.code === 200) {
        setPeriods(res.data.data)
    } else {
        console.log("Error");
    }
    })
    .catch((error) => {
    console.log(error);
    });
};

  useEffect(() => { 
    allPeriods()
    axios.get(urlbase+'/api/members',settings())
    .then((res) => {
      if(res.data.code == 200){
        setMembers(res.data.data)
      }else{
          setError(res.data.message)
          setProcessing(0)
      }
    }).catch((e) => {
        setError('There was an internal error, please try again later')
        setProcessing(0)
    })
  },[])
  
  const [addParticipant2,setAddParticipant2] = useState()
  const searchMember = () => {
  
    setAddParticipant2(1)
  }
  
  //SELECTION
  const [selection, setSelection] = useState([])
  const [selectionIds, setSelectionIds] = useState([])
  const AddSelection = (e) => {
    const isFound = members.some(element => {
      return element.member_id === e.target.value;
    });
    if(isFound){
      const found = selection.some(obj => {
        return obj.member_id === e.target.value;
      });
      if(found){
      }else{
        const NewFind = members.find(obj => {
          return obj.member_id === e.target.value;
        });
        setSelection([...selection,{id:NewFind.id,member_id:NewFind.member_id,name:NewFind.name}])
        setSelectionIds([...selectionIds,NewFind.id])
      }
    }
    //document.getElementById('search').value=''
  } 
  
  const AddSelection2 = (e) => {
    const isFound = members.some(element => {
      return element.member_id === e.target.value;
    });
    if(isFound){
      const found = members.find(obj => {
        return obj.member_id === e.target.value;
      });
      console.log(found)
      setSelection([...selection,{id:found.id,member_id:found.member_id,name:found.name}])
      setSelectionIds([...selectionIds,found.member_id])
    }
   // alert(document.getElementById('search').value)
    document.getElementById('search').value=''
  } 

  const [lettersData, setLettersData] = useState({})
  const AddLettersData = (val,field) => {
    setLettersData({...lettersData,[field]:val})
  }

  const SendLetters = () => {
    if(!lettersData.year || lettersData.year == "choose") {
        alert('Please choose a year')
        return
    }
    if(!sendToAll){
        if(selectionIds.length < 1){
            alert('Please choose at lease 1 member')
            return
        }else{
            lettersData.members = selectionIds
            lettersData.all = false
        }
    }else{
        lettersData.all = true
    }

    let val = lettersData.year
    lettersData.start = val.substring(0, 4);
    lettersData.end = val.substring(4);
    console.log(lettersData)
  }

  const [isProcessing, setIsProcessing] = useState()
  const [sendToAll, setSendToAll] = useState()
  return (
    <>
    <div className="border-b-[1px] pb-7 text-[#4F4F4F] md:flex md:flex-row md:justify-between md:items-center">
        <div className="flex flex-col space-y-2">
        <p className="text-[20px] font-[600] text-[#121927]">Send Letters</p>
        <p className="text-[#4F4F4F] font-[400] text-[16px]">
            You can send letters to members for which you have claimed gift aids on their donations.
        </p>
        {sessionStorage.getItem('chgamsSubscription') == 1 ? <p className="text-[#b30000]">All existing data will be removed when you start a paid subscription.</p> : null }
        </div>
        <div>
          {isProcessing ? <div>Please wait...</div>:
          <button onClick={SendLetters} className="border-[1px] bg-[#B30000] text-white px-2 h-[40px] cursor-pointer rounded-[4px] flex flex-row p-2 items-center space-x-3">
            Send Letters
          </button>}
        </div>
    </div>
    <div className='grid grid-cols-6 gap-x-4 mt-5 gap-y-5 mb-20 items-center'>
        <div className="col-span-3 flex flex-col text-start space-y-2">
            <div className='text-[#505050]'>Year of Claim</div>
            <select onChange={(e) => AddLettersData(e.target.value,'year')}  required className='border-[1px] rounded-[8px] p-3 outline-none text-[#4F4F4F] w-[100%] h-[50px]'>
                <option value="choose">Select year</option>
                {periods.map(({start,end},index) => {
                  return (
                    <option key={index} value={start.toString()+end.toString()}>{start == end ? start : start+'-'+end}</option>
                  )
                })}
            </select>
        </div>
        <div className='col-span-3 flex flex-row items-center space-x-2 pt-7'>
            <input type='checkbox' onChange={(e) => setSendToAll(e.target.checked)} className='border-[1px] rounded-[8px] px-3 outline-none text-[#4F4F4F] h-[50px] accent-[#B30000]'/>
            <div className='text-[#828282]'>Check to send to all members with donations</div>
        </div>
        <div className={`col-span-3 flex flex-col text-start space-y-2 ${sendToAll ? 'hidden' : ''}`}>
            <div className='text-[#505050]'>Search Member to send mails</div>
            <div className='relative'>
                <div className='absolute top-5 z-50 left-4'><img src={search} /></div>
                <input list="members" id="search" onChange={(e) => AddSelection(e)} className='relative w-full bg-[#F7F7F7] h-[50px] pl-10 rounded-[8px] border-[1px] text-[#8A8A8A] outline-none' placeholder='Search' />
                {selection ? 
                <div className='flex-col space-y-2 text-start mt-4'>
                    {selection.map(({id,member_id,name},ind) => {
                    return (
                        <div key={id} className={`flex flex-col pb-3 ${ind+1 != selection.length ? 'border-b-[1px]' : ''}`}>
                        <span className='text-[#333333] font-[400] text-[16px]'>{name}</span>
                        <span className='text-[#333333] font-[400] text-[12px]'>{member_id}</span>
                        </div>
                    )
                    })}
                </div> :null }
                <datalist onSelect={(e) => AddSelection2(e)} id="members">
                {members.map(({member_id,name},idd) => {
                    return (
                    <option key={idd} value={member_id}>{name}</option>
                    )
                })}
                </datalist> 
            </div>
        </div>
    </div>
    </>
  )
}

export default Letters