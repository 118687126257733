import React from 'react'
import Layout from '../../layout'

function Report() {
  return (
    <>
        <Layout props='report'></Layout>
    </>
  )
}

export default Report