import React from "react";
import Layout from "../../layout";

function Account() {
  return (
    <>
      <Layout props="account">
        
      </Layout>
    </>
  );
}

export default Account;
