import React,{useState,useEffect} from 'react'
import back from "../../../images/icons/back.svg"
import { Link } from 'react-router-dom'
import axios from 'axios'
import { LineWave } from  'react-loader-spinner'
import settings from '../../../components/getrequest'
import { format } from 'date-fns';
import { ThreeDots } from 'react-loader-spinner'

function GivingDetails() {
  const [base, setBase] = useState(sessionStorage.getItem('prefix') ? sessionStorage.getItem('prefix'): 'user')
  const[fetching, setFetching] = useState(1)
  const [givingInfo, setGivingInfo] = useState({})
  let urlbase = process.env.REACT_APP_BASE;
  const [processing, setProcessing] = useState(0)
  const [error, setError] = useState(0)
  useEffect(() => {
    //get giving details
    axios.get(urlbase+'/api/givings/get?id='+sessionStorage.getItem('giving_id'),settings())
    .then((res) => {
        if(res.data.code == 200){
          setGivingInfo(res.data.data)
        }else{
            console.log(res.data.message)
        }
        setProcessing(0)
        setFetching(0)
    }).catch((e) => {
        console.log('There was an internal error, please try again later')
        setProcessing(0)
        setFetching(0)
    })

  },[])
  const today = new Date().toISOString().split('T')[0];
  return (
    <>
        {fetching ? 
                    <div className='col-span-8 lg:col-span-6 flex flex-col justify-center items-center '>
                    <ThreeDots height="80" width="80" radius="9" color="#333333" ariaLabel="three-dots-loading"
                    wrapperStyle={{}} wrapperClassName="" visible={true}
                    />
                </div> :
       <div className='grid grid-cols-1 mt-5 text-start'>
        <div className='col-span-1 grid grid-cols-1 px-8 border-b pb-5'>
          <Link to={'/'+base+'/giving'} className='col-span-1 flex flex-row space-x-3'>
              <img src={back}/>
              <span className='text-[#4F4F4F] text-[16px]'>Back</span>
          </Link>
          <p className='text-[#121927] font-[600] text-[24px] mt-5'>Donation Details</p>
          <span className='text-[#4F4F4F] mt-2 flex flex-col lg:flex-row justify-between lg:items-center'>
            <span className='w-[48%]'>Here is the donation details for <span className='font-[600]'>{givingInfo.name}</span> with member ID <span className='font-[600]'>{givingInfo.member_id}</span></span>
            <span className='text-[#333333] font-[600] text-[40px]'>£{givingInfo.amount ? givingInfo.amount.toLocaleString() : 0}</span>
          </span>
        </div>
        <div className='col-span-1 grid grid-cols-6 gap-x-3 p-8 gap-y-5'>
          <div className='col-span-6 lg:col-span-2 flex flex-col space-y-2'>
            <label className='text-[#828282]'>Payment method</label>
            <div className='h-[50px] rounded-[8px] py-3 px-3 border-[1px] text-[#4F4F4F]'>{givingInfo.payment_method}</div>
          </div>
          <div className='col-span-6 lg:col-span-2 flex flex-col space-y-2'>
            <label className='text-[#828282]'>Giving type</label>
            <div className='h-[50px] rounded-[8px] py-3 px-3 border-[1px] text-[#4F4F4F]'>{givingInfo.type == 1 ? 'Individual(Offering)' : givingInfo.type == 2 ? 'Individual(Tithe)' : givingInfo.type == 3 ? ('Individual('+ (givingInfo.others? givingInfo.others : 'Others')+')') : givingInfo.type == 3 ? 'GASD' : 'Aggregated'}</div>
          </div>
          <div className='col-span-6 lg:col-span-2 flex flex-col space-y-2'>
            <label className='text-[#828282]'>Date & time</label>
            <div max={today} className='h-[50px] rounded-[8px] py-3 px-3 border-[1px] text-[#4F4F4F]'>{givingInfo.payment_date ? format(new Date(givingInfo.payment_date),'yyyy-MM-dd') : ''}</div>
          </div>
          <div className='col-span-6 lg:col-span-2 flex flex-col space-y-2'>
            <label className='text-[#828282]'>Entry officer</label>
            <div className='h-[50px] rounded-[8px] py-3 px-3 border-[1px] text-[#4F4F4F]'>{givingInfo.entry_officer}</div>
          </div>
          
        </div>
       </div>
  }
    </>
  )
}

export default GivingDetails