import React, { useState, useEffect,useRef} from "react";
import { CSVLink, CSVDownload } from "react-csv";
import create from "../../../images/icons/addadmin.svg";
import download from "../../../images/icons/download.svg";
import filter from "../../../images/icons/filter.svg";
import search from "../../../images/icons/search.svg";
import delet from "../../../images/icons/delete2.svg";
import deleteRed from "../../../images/icons/deleteRed.svg";
import edit from "../../../images/icons/edit.svg";
import { Link } from "react-router-dom";
import axios from "axios";
import settings from "../../../components/getrequest";

function RolesBody() {
  let urlbase = process.env.REACT_APP_BASE;
  const [searchItem, setSearchItem] = useState("");
  //Group types
  let [User, setUser] = useState(false)
  let [Admin, setAdmin] = useState(false) 
  let [SuperAdmin, setSuperAdmin] = useState(false)
  const RoleTypes = [{title:'User',set:setUser},{title:'Admin',set:setAdmin},{title:'Super Admin',set:setSuperAdmin}];
  //Applying Filters

  useEffect(() => {
    applyFilters();
  }, [User,Admin,SuperAdmin]);
  const handleCheckboxChange = (e,name) => {
    name(e.target.checked)
  };

  const applyFilters = () => {
    let url = '/api/settings/admins?user='+(User ? 1:0)+'&admin='+(Admin ? 1:0)+'&super='+(SuperAdmin ? 1:0)
    fetchAllAdmins(url)
  };

  //filter
  const [filterr, setFilterr] = useState();
  const [add, setAdd] = useState();
  const [edits, setEdits] = useState();
  const [role, setRole] = useState([]);
  const [data, setData] = useState({
    first_name: "",
    last_name: "",
    role: "",
    email: "",
  });
  const filterAction = (e) => {
    e.stopPropagation();
    setFilterr(!filterr)
  };
  const filterRef = useRef(null);
const handleOutsideClick = (event) => {
  setTimeout(() => {
    if (filterRef.current && !filterRef.current.contains(event.target)) {
      setFilterr(false);
    }
  }, 0);
};

useEffect(() => {
  document.addEventListener('click', handleOutsideClick);
  return () => {
    document.removeEventListener('click', handleOutsideClick);
  };
}, []);
  const rolesheaders = [
    { label: "id", key: "id" },
    { label: "name", key: "name" },
    { label: "date", key: "date" },
    { label: "group_id", key: "group_id" },
    { label: "amount", key: "amount" },
    { label: "type", key: "type" },
    { label: "payment", key: "payment" },
  ];
  const [rolesData, setrolesData] = useState([]);

  const fetchAllAdmins = async (url) => {
    axios
      .get(urlbase + url , settings())
      .then((res) => {
        if (res.data.code === 200) {
          setrolesData(res.data.data || []);
        } else {
          console.log("Error");
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  useEffect(() => {
    fetchAllAdmins("/api/settings/admins");
  }, []);

  //edit Group
  const [GroupModal, setGroupModal] = useState();
  const setTheModal = () => {
    setActiveDonation(null);
    setGroupModal(1);
  };

  //delete donation
  const [deleteModal, setDeleteModal] = useState();
  const [adminId, setAdminId] = useState();
  const setTheDelete = (id) => {
    setAdminId(id)
    setActiveDonation(null);
    setDeleteModal(1);
  };

  const handleDelete = () => {
    axios
    .delete(urlbase + "/api/settings/admins/"+adminId, settings())
    .then((res) => {
      if (res.data.code === 200) {
          alert('Admin deleted successfully')
          window.location.reload();
      } else {
        alert(res.data.message)
        setDeleteModal(0);
        console.log("Error");
      }
    })
    .catch((error) => {
      alert('There was an error, please try again later')
      setDeleteModal(0);
      console.log(error);
    });
  }

  //Active donation
  const [activeDonation, setActiveDonation] = useState();
  const changeActiveDon = (id) => {
    if (activeDonation == id) setActiveDonation(null);
    else setActiveDonation(id);
  };

  const fetchAllRoles = async () => {
    axios
      .get(urlbase + "/api/settings/roles", settings())
      .then((res) => {
        if (res.data.code === 200) {
          setRole(res.data.data || []);
        } else {
          alert("Error");
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  useEffect(() => {
    fetchAllRoles();
  }, []);

  const [editData, setEditData] = useState()
  const setEditModal = (id,last_name,first_name,email,role) => {
    setEdits(id)
    setEditData({id:id,first_name:first_name,last_name:last_name,email:email,role:role})
  }
  const handleChange = (e,field) => {
      setEditData({
        ...editData,
        [field]: e.target.value,
      });
  };

  const handleChangeCreate = (e,field) => {
    setData({
      ...data,
      [field]: e.target.value,
    });
};

  const handleUpdate = async (event) => {
    setIsProcessing(1)
      axios
        .post(urlbase + "/api/settings/admins/update",editData, settings())
        .then((res) => {
          if (res.data.code === 200) {
            alert('Admin updated successfully')
            window.location.reload();
        } else {
          alert(res.data.message)
          setEdits(0);
          console.log("Error");
        }
        setIsProcessing(0)
        })
        .catch((error) => {
          alert('There was an error, please try later')
          setEdits(0);
          console.log(error)
          setIsProcessing(0)
        });
  };

  const handleSubmit = async (event) => {
    setIsProcessing(1)
    event.preventDefault();
    console.log(data)
    if (!data.role || !data.email || !data.first_name || !data.last_name) {
      alert("Please fill all fields");
    } else {
      axios
        .post(urlbase + "/api/settings/create/admin",data, settings())
        .then((res) => {
          if (res.data.code === 200) {
            alert(res.data.message)
            window.location.reload();
        } else {
          alert(res.data.message)
          setAdd(0);
          console.log("Error");
        }
        setIsProcessing(0)
        setAdd(0)
        })
        .catch((error) => {
          alert('There was an error, please try later')
          setAdd(0);
          console.log(error)
          setIsProcessing(0)
        });
    }
  };

  const[isProcessing,setIsProcessing] = useState()

  return (
    <>
    {edits ? (

        <div
          id="defaultModal"
          tabIndex="-1"
          aria-hidden="true"
          className='fixed top-0 left-0 right-0 h-screen bg-black bg-opacity-50 inset-0 items-center justify-center z-50 w-full p-4 overflow-x-hidden overflow-y-auto md:inset-0 h-[calc(100%-1rem)] max-h-full'
        >
          <div className="relative w-[95%] lg:w-[40%] mt-[5%] lg:mt-[2%] max-h-full mx-auto text-[#333]">
            <div className="relative bg-white shadow pt-3 rounded-[8px]">
              <div className="flex items-start justify-between border-b-[1px] px-8 pb-3">
                <p className="text-[#121927] text-[20px] font-[600]">
                  Edit Admin
                </p>
                <button
                  onClick={() => setEdits(0)}
                  type="button"
                  className="text-gray-300 font-[200] bg-transparent hover:bg-[#F2F2F2] hover:text-black rounded-full p-1.5 ml-auto inline-flex items-center"
                  data-modal-hide="defaultModal"
                >
                  <svg
                    aria-hidden="true"
                    className="w-5 h-5 "
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                      clipRule="evenodd"
                    ></path>
                  </svg>
                  <span className="sr-only">Close modal</span>
                </button>
              </div>
              <div className="p-8">
                <div className="col-span-2 flex flex-col space-y-2 mx-auto py-4 w-full">
                  <label>Admin First Name</label>
                  <div className="w-full relative">
                    <input
                      onChange={(e) => handleChange(e,'first_name')}
                      type="text"
                      name="adminName"
                      className="border-[1px] rounded-[4px] w-full h-[50px] px-3 outline-none"
                      placeholder="First Name"
                      defaultValue={editData.first_name}
                    />
                  </div>
                </div>
                <div className="col-span-2 flex flex-col space-y-2 mx-auto py-4 w-full">
                  <label>Admin Last Name</label>
                  <div className="w-full relative">
                    <input
                      onChange={(e) => handleChange(e,'last_name')}
                      type="text"
                      name="adminName"
                      className="border-[1px] rounded-[4px] w-full h-[50px] px-3 outline-none"
                      placeholder="Last Name"
                      defaultValue={editData.last_name}
                    />
                  </div>
                </div>
                <div className="col-span-2 flex flex-col space-y-2 mx-auto py-4 w-full">
                  <label>Email Address</label>
                  <div className="w-full relative">
                    <input
                      onChange={(e) => handleChange(e,'email')}
                      type="email"
                      name="email"
                      className="border-[1px] rounded-[4px] w-full h-[50px] px-3 outline-none"
                      placeholder="Enter Email Address"
                      defaultValue={editData.email}
                    />
                  </div>
                </div>
                <div className="col-span-2 flex flex-col space-y-2 mx-auto py-4 w-full">
                  <label>Type of Access</label>
                  <div className="w-full relative">
                    <select
                      onChange={(e) => handleChange(e,'role')}
                      name="role"
                      className="border-[1px] rounded-[4px] w-full h-[50px] px-3 outline-none"
                      placeholder="Select Access type"
                    >
                      {role.map((item) => (
                        <option key={item.id} selected={editData.role==item.id? true: false} value={item.id}>
                          {item.role}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
              </div>
              {isProcessing ? <div>Please wait...</div> : 
              <div className="flex flex-row justify-end mt-5 space-x-2 px-8 bg-[#F7F7F7] rounded-b-[8px] py-4">
                <button
                  data-modal-hide="defaultModal"
                  type="button"
                  onClick={() => setEdits(0)}
                  className="border-[1px] border-[#828282] text-[#121927] w-[102px] h-[50px] rounded-[8px] bg-white"
                >
                  Cancel
                </button>
                <button
                  data-modal-hide="defaultModal"
                  type="button"
                  onClick={handleUpdate}
                  className={`border-[1px] w-[150px] h-[50px] bg-[#B30000] rounded-[8px] border-none text-white`}
                >
                  Update Admin
                </button>
              </div>
              }
            </div>
          </div>
        </div>
    ) :null}
      {add ? (
        <div
          id="defaultModal"
          tabIndex="-1"
          aria-hidden="true"
          className='fixed top-0 left-0 right-0 h-screen bg-black bg-opacity-50 inset-0 items-center justify-center z-50 w-full p-4 overflow-x-hidden overflow-y-auto md:inset-0 h-[calc(100%-1rem)] max-h-full'
        >
          <div className="relative w-[95%] lg:w-[40%] mt-[25%] lg:mt-[2%] max-h-full mx-auto text-[#333]">
            <div className="relative bg-white shadow pt-3 rounded-[8px]">
              <div className="flex items-start justify-between border-b-[1px] px-8 pb-3">
                <p className="text-[#121927] text-[20px] font-[600]">
                  Add New Admin
                </p>
                <button
                  onClick={() => setAdd(0)}
                  type="button"
                  className="text-gray-300 font-[200] bg-transparent hover:bg-[#F2F2F2] hover:text-black rounded-full p-1.5 ml-auto inline-flex items-center"
                  data-modal-hide="defaultModal"
                >
                  <svg
                    aria-hidden="true"
                    className="w-5 h-5 "
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                      clipRule="evenodd"
                    ></path>
                  </svg>
                  <span className="sr-only">Close modal</span>
                </button>
              </div>
              <div className="p-8">
                <div className="col-span-2 flex flex-col space-y-2 mx-auto py-4 w-full">
                  <label>Admin First Name</label>
                  <div className="w-full relative">
                    <input
                      onChange={(e) => handleChangeCreate(e,'first_name')}
                      type="text"
                      className="border-[1px] rounded-[4px] w-full h-[50px] px-3 outline-none"
                      placeholder="Enter First Name"
                    />
                  </div>
                </div>
                <div className="col-span-2 flex flex-col space-y-2 mx-auto py-4 w-full">
                  <label>Admin Last Name</label>
                  <div className="w-full relative">
                    <input
                      onChange={(e) => handleChangeCreate(e,'last_name')}
                      type="text"
                      className="border-[1px] rounded-[4px] w-full h-[50px] px-3 outline-none"
                      placeholder="Enter Last Name"
                    />
                  </div>
                </div>
                <div className="col-span-2 flex flex-col space-y-2 mx-auto py-4 w-full">
                  <label>Email Address</label>
                  <div className="w-full relative">
                    <input
                      onChange={(e) => handleChangeCreate(e,'email')}
                      type="email"
                      name="email"
                      className="border-[1px] rounded-[4px] w-full h-[50px] px-3 outline-none"
                      placeholder="Enter Email Address"
                    />
                    <span className="text-[12px] text-[#B30000]">Please make sure this email is valid</span>
                  </div>
                </div>
                <div className="col-span-2 flex flex-col space-y-2 mx-auto py-4 w-full">
                  <label>Type of Access</label>
                  <div className="w-full relative">
                    <select
                      value={data.role}
                      onChange={(e) => handleChangeCreate(e,'role')}
                      name="role"
                      className="border-[1px] rounded-[4px] w-full h-[50px] px-3 outline-none"
                      placeholder="Select Access type"
                    >
                      <option value="default">Select Access type</option>
                      {role.map((item) => (
                        <option key={item.id} value={item.id}>
                          {item.role}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
              </div>
              {isProcessing ? <div>Please wait...</div> : 
              <div className="flex flex-row justify-end mt-5 space-x-2 px-8 bg-[#F7F7F7] rounded-b-[8px] py-4">
                <button
                  data-modal-hide="defaultModal"
                  type="button"
                  onClick={() => setAdd(0)}
                  className="border-[1px] border-[#828282] text-[#121927] w-[102px] h-[50px] rounded-[8px] bg-white"
                >
                  Cancel
                </button>
                <button
                  data-modal-hide="defaultModal"
                  type="button"
                  onClick={handleSubmit}
                  className={`border-[1px] w-[150px] h-[50px] bg-[#B30000] rounded-[8px] border-none text-white`}
                >
                  Add Admin
                </button>
              </div>
              }
            </div>
          </div>
        </div>
      ) : null}

      {deleteModal ? 
        <div
          id="defaultModal"
          tabIndex="-1"
          aria-hidden="true"
          className="fixed top-0 left-0 right-0 h-screen bg-black bg-opacity-50 inset-0 items-center justify-center z-50 w-full p-4 overflow-x-hidden overflow-y-auto md:inset-0 h-[calc(100%-1rem)] max-h-full"
        >
          <div className="relative w-[50%] lg:w-[35%] mt-[10%] max-h-full mx-auto text-[#333]">
            <div className="relative bg-white shadow pt-4 rounded-[8px]">
              <div className="flex items-start justify-between px-8 pb-4">
                <h3 className="text-[#121927] text-[20px] font-[600]"></h3>
                <button
                  onClick={() => setDeleteModal(0)}
                  type="button"
                  className="text-gray-300 font-[200] bg-[#F2F2F2] hover:text-black rounded-full p-1.5 ml-auto inline-flex items-center"
                  data-modal-hide="defaultModal"
                >
                  <svg
                    aria-hidden="true"
                    className="w-5 h-5 "
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                      clipRule="evenodd"
                    ></path>
                  </svg>
                  <span className="sr-only">Close modal</span>
                </button>
              </div>
              <div className="flex flex-col space-y-4 mt-3 px-5">
                <div className="grid mb-2">
                  <img className="mx-auto" src={deleteRed} />
                </div>
                <span className="text-[#121927] font-[600] text-[24px] text-center">
                  Delete Admin
                </span>
                <span className="text-[#4F4F4F] text-[16px] text-center">
                  Deleted admin would have to be re-created. Are you sure you
                  want to delete?
                </span>
              </div>
              <div className="flex flex-col mt-5 space-y-3 px-8 py-4">
                <button onClick={handleDelete}
                  data-modal-hide="defaultModal"
                  type="button"
                  className="border-[1px] w-full h-[50px] bg-[#EB5757] rounded-[8px] border-none text-white"
                >
                  Yes, delete admin
                </button>
                <button
                  onClick={() => setDeleteModal(0)}
                  data-modal-hide="defaultModal"
                  type="button"
                  className="border-[#828282] w-full h-[50px] rounded-[8px] bg-[#F7F7F7] text-[#333333]"
                >
                  No, Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
       : null}
      <div className="flex flex-col space-y-4 pb-10">
        <div className="flex flex-col space-y-3 lg:space-y-0 lg:flex-row lg:justify-between lg:items-center border-b-[1px] pb-7">
          <div className="flex flex-col space-y-2">
            <span className="text-[#121927] font-[600]">All Administrators</span>
            <span>
              Here you can see the list of admins and make changes to their
              access.
            </span>
          </div>
          <div className="flex flex-row space-x-3">
            <div
              className="border-[1px] bg-[#B30000] px-2 h-[40px] cursor-pointer rounded-[4px] flex flex-row p-2 items-center space-x-3"
              onClick={() => setAdd(1)}
            >
              <img src={create} />
              <p className="text-white text-[15px] font-[600]">Add new Admin</p>
            </div>
          </div>
        </div>
        
        <>
        <div className="col-span-1 flex lg:flex-row flex-col space-y-4 lg:space-y-0 justify-between mt-8">
          <div className="relative">
            <div className="absolute top-3 z-50 left-4">
              <img src={search} />
            </div>
            <input
              onChange={(e) => setSearchItem(e.target.value)}
              className="relative w-[321px] bg-[#F7F7F7] h-[40px] pl-10 rounded-[8px] border-[1px] text-[#8A8A8A] outline-none"
              placeholder="Search"
            />
          </div>
          <div className="flex flex-row space-x-4 items-center">
            <div>
              <div
                onClick={(e) => filterAction(e)}
                className="flex flex-row space-x-3 border-[1px] rounded-[4px] p-1 px-3 h-[40px] cursor-pointer"
              >
                <img src={filter} />
                <span>Filter</span>
              </div>
              {filterr ? (
                <div ref={filterRef} className="absolute rounded-[4px] flex flex-col z-50 bg-white space-y-3 shadow-md right-10 py-2">
                  {RoleTypes.map((item, id) => {
                    return (
                      <div className="flex flex-row space-x-2 px-4 cursor-pointer hover:bg-[#F7F7F7] py-2">
                        <input checked={item.title == 'Admin' ? Admin : item.title == 'User' ? User : SuperAdmin} onChange={(e) => handleCheckboxChange(e,item.set)} type="checkbox" className="accent-[#B30000]" />
                        <p>{item.title}</p>
                      </div>
                    );
                  })}
                </div>
              ) : null}
            </div>
          </div>
        </div>
        <div className="w-full overflow-x-auto border-[1px] rounded-[8px]">
          <table className="w-full">
            <thead className="bg-[#F7F7F7] w-full rounded-[8px]">
              <tr className="text-[#292D32] font-[400]">
                <th className="px-7 py-1 text-start text-[#292D32] font-[400]">
                  Admin Name
                </th>
                <th className="px-9 py-1 text-start text-[#292D32] font-[400]">
                  Email Address
                </th>
                <th className="px-9 py-1 text-start text-[#292D32] font-[400]">
                  Date Added
                </th>
                <th className="px-9 py-1 text-start text-[#292D32] font-[400]">
                  Last Active
                </th>
                <th className="pl-7 pr-3 py-1 text-start text-[#292D32] font-[400]">
                  Access Type
                </th>
                <th className="pl-7 py-1 text-start text-[#292D32] font-[400]"></th>
                <th className="px-6 py-1 text-start text-[#292D32] font-[400]"></th>
              </tr>
            </thead>
            {rolesData.length > 0 ? 
            <tbody className="divide-y divide-gray-200">
              {rolesData
                .filter(
                  (row) =>
                    !searchItem.length ||
                    row.first_name
                      .toString()
                      .toLowerCase()
                      .includes(searchItem.toString().toLowerCase())
                )
                .map(({ id, first_name,last_name, date_added, last_active, email, role,role_id }, index) => {
                  return (
                    <tr key={id}>
                      <td className="px-7 py-3 text-start text-[#4F4F4F]">
                        {first_name+' '+last_name}
                      </td>
                      <td className="px-9 py-3 text-start text-[#4F4F4F]">
                        {email}
                      </td>
                      <td className="px-9 py-3 text-start text-[#4F4F4F]">
                        {date_added}
                      </td>
                      <td className="px-9 py-3 text-start text-[#4F4F4F]">
                        {last_active ? last_active : 'Never'}
                      </td>
                      <td className="pl-7 pr-3 py-3 text-start text-[#4F4F4F]">
                        {role}
                      </td>
                      <td className="pl-4 py-3 text-start text-[#4F4F4F]">
                        <img
                          className="w-[20px] h-[20px] cursor-pointer"
                        //   onClick={() => setTheModal()}
                          onClick={() => setEditModal(id,last_name,first_name,email,role_id)}
                          src={edit}
                        />
                      </td>
                      <td className="px-4 py-3 text-start text-[#4F4F4F]">
                        <img
                          className="w-[20px] h-[20px] cursor-pointer"
                          onClick={() => setTheDelete(id)}
                          src={delet}
                        />
                      </td>
                    </tr>
                  );
                })}
            </tbody>
            : <div className="mt-4">No Admins retrieved</div> }
          </table>
        </div>
        </> 
      </div>
    </>
  );
}

export default RolesBody;
