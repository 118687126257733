import React from 'react'
import Layout from '../../layout'

function Giving() {
  return (
    <>
        <Layout props='giving'>
          <p>hello</p>
        </Layout>
    </>
  )
}

export default Giving