import React, {useState,useEffect, useRef} from 'react'
import give from "../../../images/icons/give.svg"
import download from "../../../images/icons/download.svg"
import search from "../../../images/icons/search.svg"
import success from "../../../images/membersuccess.svg"
import filter from "../../../images/icons/filter.svg"
import view from "../../../images/icons/view.svg"
import delet from "../../../images/icons/delete.svg"
import individual from "../../../images/icons/individual.svg"
import memberupload from "../../../images/icons/memberupload.svg"
import deleteRed from "../../../images/icons/deleteRed.svg"
import more from "../../../images/icons/more.svg" 
import upload from "../../../images/icons/upload.svg"
import { ThreeDots } from 'react-loader-spinner'
import { Link } from 'react-router-dom'
import { CSVLink, CSVDownload } from "react-csv";
import axios from 'axios'
import { LineWave } from  'react-loader-spinner' 
import settings from '../../../components/getrequest'
import file from "../../../images/icons/file.svg"
import remove from "../../../images/icons/remove.svg"
import { IoMdExit } from "react-icons/io";
import Papa from "papaparse"
import * as XLSX from 'xlsx';

function Members() {
  const [fileChosen, setFileChosen] = useState()
  const [theFile, setTheFile] = useState({})
  const [data, dataSet] = useState()
  const [uploadedData, setUploadedData] = useState([])
  const [base, setBase] = useState(sessionStorage.getItem('prefix') ? sessionStorage.getItem('prefix'): 'user')
  let urlbase = process.env.REACT_APP_BASE;
  const [add, setAdd] = useState()
  const [membersAdded, setMembersAdded] = useState()
  const [memberData, setMemberData] = useState({country:'United Kingdom'})
  const [groups, setGroups] = useState([])
  const [error, setError] = useState(0)
  const [processing, setProcessing] = useState(0)
  const [membersData, setmembersData] = useState([])
  const[fetching, setFetching] = useState(1)
  const sliceValue = 25
  const [MembersArray, setMembersArray] = useState([])
  const[MembersCurrent, setMembersCurrent] = useState([])
  const[MembersCurrentDefault, setMembersCurrentDefault] = useState()
  const [currentPage,setCurrentPage] = useState(0)

  const [tableStart, setTableStart] = useState(0)
  const [tableEnd, setTableEnd] = useState(5) 
  const getMembers = async (url) => {
    setFetching(1)
    axios.get(urlbase+url, settings())
      .then((res) => {
        if (res.data.code === 200) {
          if(res.data.data.length > 0) {
            setmembersData(res.data.data)
            const allData = res.data.data;
            const slicedData = [];
            for (let i = 0; i < allData.length; i += sliceValue) {
              slicedData.push(allData.slice(i, i + sliceValue));
            }
            setMembersCurrent(slicedData[0])
            setPageCount(slicedData[0].length)
            setMembersCurrentDefault(slicedData[0])
            setMembersArray(slicedData);
          }else{
            setmembersData([])
            setMembersCurrent([])
            setMembersCurrentDefault([])
            setMembersArray([]);
          }

        } else {
          setError(res.data.message);
          setProcessing(0);
        }
        setFetching(0)
      })
      .catch((e) => {
        setError('There was an internal error, please try again later');
        setProcessing(0);
        setFetching(0)
      });
  }

  const getGroups = () => {
    //get groups 
    axios.get(urlbase+'/api/groups',settings())
    .then((res) => {
        if(res.data.code == 200){
          setGroups(res.data.data)
        }else{
            setError(res.data.message)
            setProcessing(0)
        }
    }).catch((e) => {
        setError('There was an internal error, please try again later')
        setProcessing(0)
    })
  }
  useEffect(() => {
    getMembers('/api/members') 
    getGroups()
  },[])
  const AddMemberData = (value,field) => {
    setMemberData({...memberData, [field]:value})
  }

  //Applying filter
  let [male, setMale] = useState(false)
  let [female, setFemale] = useState(false)
  let [dateF, setDateF] = useState(null)
  useEffect(() => {
    applyFilters();
  }, [male, female, dateF]);
  const handleCheckboxChange = (e,name) => {
    if(name == 'Male'){
      setMale(e.target.checked)
    }else if (name == 'Female'){
      setFemale(e.target.checked)
    }
  };
  const dateFilter = (value) => {
      if(value) setDateF(value)
      else setDateF(null)
  };
    const applyFilters = () => {
      if (male && female && dateF) {
        getMembers('/api/members?date='+dateF) 
      } else if (female && male) {
        getMembers('/api/members')
      } else if (female && dateF) {
        getMembers('/api/members?female=1&date='+dateF)
      } else if (male && dateF) {
        getMembers('/api/members?male=1&date='+dateF)
      } else if (female) {
        getMembers('/api/members?female=1')
      } else if (dateF) {
        getMembers('/api/members?date='+dateF)
      } else if (male) {
        getMembers('/api/members?male=1')
      } else {
        getMembers('/api/members')
      }
    };

  function AddMember() {

    return (
      <>
        <div id="defaultModal" tabIndex="-1" aria-hidden="true" className="fixed top-0 left-0 right-0 z-50 w-full p-4 overflow-x-hidden overflow-y-auto md:inset-0 h-[calc(100%-1rem)] max-h-full">
          <div className="relative w-[50%] lg:w-[35%] mt-[10%] max-h-full mx-auto text-[#333]">
              <div className="relative bg-white shadow pt-4 rounded-[8px]">
                  <div className="flex items-start justify-between px-8 pb-4">
                      <h3 className="text-[#121927] text-[20px] font-[600]">
                      </h3>
                      <button onClick={() => setDeleteModal(0)} type="button" className="text-gray-300 font-[200] bg-[#F2F2F2] hover:text-black rounded-full p-1.5 ml-auto inline-flex items-center" data-modal-hide="defaultModal">
                          <svg aria-hidden="true" className="w-5 h-5 " fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
                          <span className="sr-only">Close modal</span>
                      </button>
                  </div>
                  <div className='flex flex-col space-y-4 mt-3 px-5'>
                    <div className='grid mb-2'><img className='mx-auto' src={deleteRed} /></div>
                    <span className='text-[#121927] font-[600] text-[24px]'>Delete Giving Details</span>
                    <span className='text-[#4F4F4F] text-[16px]'>Deleted donation can not be retrieved. Are you sure you want to delete?</span>
                  </div>
                  <div className="flex flex-col mt-5 space-y-3 px-8 py-4">
                      <button data-modal-hide="defaultModal" type="button" className="border-[1px] w-full h-[50px] bg-[#EB5757] rounded-[8px] border-none text-white">Yes, delete donation</button>
                      <button onClick={() => setDeleteModal(0)} data-modal-hide="defaultModal" type="button" className="border-[#828282] w-full h-[50px] rounded-[8px] bg-[#F7F7F7] text-[#333333]">No, Cancel</button>
                  </div>
              </div>
          </div>
      </div>
      </>
    )
  }

  const membersheaders = [
    { label: "id", key: "id" },
    { label: "member id", key: "member_id" },
    { label: "member name", key: "name" },
    { label: "member email", key: "email" },
    { label: "phone number", key: "phone" },
    { label: "gender", key: "gender" },
    { label: "date created", key: "created_at" },
    { label: "total donations", key: "total_donations" },
  ]

const [pageCount, setPageCount] = useState(0) 
let [pageNumbers, setPageNumbers] = useState(0) 

const PreviousPage = () => {
  if(currentPage > 0){
    if(MembersArray.length > 5){
      if(tableStart != 0){
        setTableStart(tableStart-1)
        setTableEnd(tableEnd-1)
      }
    }
    let thenewPage = currentPage
    let counted = pageCount
    let start = 2
    while(start > 0){
        counted -= MembersArray[thenewPage].length
        thenewPage--;
        start--;
    }
    setPageNumbers(counted)
    setPageCount(pageCount - MembersArray[currentPage].length)
    setCurrentPage(currentPage-1)
    setMembersCurrent(MembersArray[currentPage-1])
    scrollToTop()
  }
}

const NextPage = () => {
    if(currentPage != MembersArray.length-1){
      if(MembersArray.length > 5){
        if(MembersArray.length != tableEnd){
          setTableStart(tableStart+1)
          setTableEnd(tableEnd+1)
        }
      }
      let thenewPage = currentPage
      let counted = 0
      while(thenewPage >= 0){
          counted += thenewPage > 0 ? MembersArray[thenewPage-1].length : 0 + MembersArray[thenewPage].length
          thenewPage--;
      }
      setPageNumbers(counted)
      setCurrentPage(currentPage+1)
      setMembersCurrent(MembersArray[currentPage+1])
      setPageCount(pageCount+MembersArray[currentPage+1].length)
      scrollToTop()
  }
}

const numberedPage = (id) => {
  let count = 0
  let counted = 0;
  while(count < id){
    counted += MembersArray[count].length
    count++;
  }
  setPageNumbers(counted)
  setPageCount(counted+MembersArray[id].length)

  setCurrentPage(id)
  setMembersCurrent(MembersArray[id])
  scrollToTop()
}

const FirstPage = () => {
  numberedPage(0)
  setTableStart(0)
  setTableEnd(5)
}

const LastPage = () => {
  numberedPage(MembersArray.length-1)
  if(MembersArray.length > 5){
      setTableStart(MembersArray.length - 5)
      setTableEnd(MembersArray.length)
  }
}

function scrollToTop(){
  var targetElement = document.getElementById("members");
        targetElement.scrollIntoView({
          behavior: "smooth",
  });
}
const [searchItem, setSearchItem] = useState("");
//Active members
const [activemembers, setActivemembers] = useState()
const changeActiveDon = (e,id) => {
  e.stopPropagation()
  if(activemembers == id) setActivemembers(null)
  else setActivemembers(id)
}


//Members types
const MembersTypes = ['Female','Male']

//filter
const [filterr, setFilterr] = useState()
const filterAction = (e) => {
  e.stopPropagation();
  setFilterr(!filterr)
}

//edit Members
const [MembersModal, setMembersModal] = useState()
const setTheModal = () => {
  setActivemembers(null)
  setMembersModal(1)
}

//delete members
const [deleteModal, setDeleteModal] = useState()
const [deleteProcessing, setDeleteProcessing] = useState()
const setTheDelete = (id) => {
  setActivemembers(null)
  setDeleteModal(id)
}
const performDelete = () => {
  setDeleteProcessing(1)
  axios.delete(urlbase+'/api/members/member?id='+deleteModal,settings())
  .then((res) => {
    if(res.data.code == 200){
      setDeleteModal(null)
      alert('Member exited successfully');
      window.location.reload()
    }else{
      alert(res.data.message);
    }
    setDeleteProcessing(0)
  }).catch(() => {
    alert('There was an error, please try again');
    setDeleteProcessing(0)
  })
}

//view details
const viewDetails = (id) => {
  sessionStorage.setItem('member_id',id)
  window.location.href='/'+base+"/member-details"
}
const [addingProcessing,setAddingProcessing] = useState()
const [addingError,setAddingError] = useState()

function isValidPostcode(postcode) {
  const postcodeRegex = /^[A-Z][A-Z\d]{1,3}\s\d[A-Z]{2}$/
  return postcodeRegex.test(postcode);
}

const PerformAdd = (e) => {
  e.preventDefault()
  if(!memberData.gender || (memberData.gender != 1 && memberData.gender != 2)){
    alert('Choose gender')
    return
  }
  if(memberData.group_id == 'choose'){
    memberData.group_id = null
  }
  if(memberData.wedding_date){
    const dob = new Date(memberData.birthday);
    dob.setFullYear(dob.getFullYear() + 18);
    if (memberData.wedding_date < dob) {
      alert('Wedding date should be at least 18 years after the date of birth.');
      return
    } 
  }
  if(memberData.post_code){
    if(!isValidPostcode(memberData.post_code)){
      alert('Invalid post code')
      return
    }
  }
  memberData.gift_aid_declaration == 'on' ? memberData.gift_aid_declaration = 1 :memberData.gift_aid_declaration=0

  setAddingProcessing(1)
  axios.post(urlbase+'/api/members/create',memberData,settings())
  .then((res) => {
    if(res.data.code == 200){
      setAdd(0)
      setMembersAdded(1)
    }else{
      alert(res.data.message);
    }
    setAddingProcessing(0)
  }).catch(() => {
    //setAddingError('There was an error, please try again')
    alert('There was an error, please try again');
    setAddingProcessing(0)
  })
}
const filterRef = useRef(null);
const filterRef2 = useRef(null);
const handleOutsideClick = (event) => {
  setTimeout(() => {
    if (filterRef.current && !filterRef.current.contains(event.target)) {
      setFilterr(false);
    }
    if (filterRef2.current && !filterRef2.current.contains(event.target)) {
      setActivemembers(null)
    }
  }, 0);
};

useEffect(() => {
  document.addEventListener('click', handleOutsideClick);
  return () => {
    document.removeEventListener('click', handleOutsideClick);
  };
}, []);

const addTheMember = (id) => {
  setAdd(id)
}
const today = new Date().toISOString().split('T')[0];

const [entryType, setEntryType] = useState(0)

const [uploadMem, setUploadMem] = useState(0)

const memberAddType = () => {
  if(sessionStorage.getItem('chgamsSubscription') != 4 && sessionStorage.getItem('chgamsSubscription') != 0){
    setEntryType(1)
  }else{
    addTheMember(1)
  }
}

const makeSelection = () => {
  setEntryType(0)
  if(entryType == 3){
    setUploadMem(1)
  }else{
    addTheMember(1)
  }
}

const selectFile = () => {
  document.getElementById('file').click()
}

const fileChange = (e) => {
  if(!e.target.files[0]){
      setFileChosen(0)
      document.getElementById('file').value=null
      return
  }
  let file = e.target.files[0]
  if (file.type != 'text/csv' && !['application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', 'application/vnd.ms-excel'].includes(file.type)) {
      setFileChosen(0)
      document.getElementById('file').value=null
      setError('Invalid file type')
  }else{
      setTheFile(file)
      setFileChosen(1)
      setError(null)
      parseCSV(e)
  }
}

const removeFile = () => {
  document.getElementById('file').value=null
  dataSet(0)
  setTheFile(null)
  setFileChosen(0)
  setError(null)
}

const parseCSV = (e) => {
  const file = e.target.files[0];

  // Check if a file is selected
  if (!file) {
      return;
  }

  const reader = new FileReader();

  reader.onload = function (e) {
      const data = e.target.result;
      // Check if the file type is either CSV or Excel
      if (file.type === 'text/csv' || file.type === 'application/vnd.ms-excel' || file.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
          // Parse CSV or Excel based on file type
          if (file.type === 'text/csv' || file.name.endsWith('.csv')) {
              Papa.parse(data, {
                  header: true,
                  skipEmptyLines: true,
                  complete: function (results) {
                      setUploadedData(results.data);
                  },
              });
          } else {
              const workbook = XLSX.read(data, { type: 'binary' });

              const sheetName = workbook.SheetNames[0];
              const sheetData = XLSX.utils.sheet_to_json(workbook.Sheets[sheetName], {
                  raw: false,
                  dateNF: 'yyyy-mm-dd',
                  cellDates: true,
                  header: 1, // Assuming the first row contains headers
              });
      
              // Check if headers are present in the first row
              const hasHeaders = sheetData.length > 0 && Object.keys(sheetData[0]).length > 0;

              // Transform Excel data into an array of objects
              const transformedData = (hasHeaders ? sheetData.slice(1) : sheetData).map((row) => {
                  const rowData = {};

                  // Define the column indices
                  const columnIndex = {
                      firstname: 0,
                      lastname: 1,
                      phone: 2,
                      email: 3,
                      gender: 4,
                      city: 5,
                      street: 6,
                      post: 7,
                      declaration: 8,
                  };

                  // Fill in the column data for each row
                  Object.keys(columnIndex).forEach((column) => {
                      const colIndex = columnIndex[column];
                      rowData[column] = row[colIndex] !== undefined && row[colIndex] !== null ? String(row[colIndex]).trim() : '';
                  });

                  return rowData;
              });

              setUploadedData(transformedData);
              console.log(transformedData)
          }
      } else {
          // Handle invalid file type
          setUploadedData([]);
          setError('Invalid file type');
      }
  };

  reader.readAsBinaryString(file);
}

const processAdding = () => {
  try{
    if(!fileChosen){
      alert('Please choose a file')
      return;
    }
      let inputed = uploadedData;
      let err = 0;
      let count = 0;
      inputed.forEach(e => {
          if(!e.firstname || !e.lastname || !e.phone || !e.email || !e.gender || !e.city || !e.street || !e.post || !e.declaration) {
              err+=1;
          }
          count+=1;
      })
      if(err > 0){
          alert('Some data in your file are missen or incorrect, check the format to ensure you are providing all required information');
          return 0;
      }
      if(sessionStorage.getItem('chgamsSubscription') == 4 || sessionStorage.getItem('chgamsSubscription') == 5){
        if(count > 100){
          alert('Sorry, you cannot upload more than 100 records at once');
          return 0;
      }
      }else{
        if(count > 70){
          alert('Sorry, you cannot upload more than 70 records at once. Upgrade to our Gold plan to upload more');
          return 0;
      }
      }

      setAddingProcessing(1)

      let allUploaded = 0;
      const uploadPromises = [];
      inputed.forEach(e => {
          let newMember = {
              first_name: e.firstname,
              last_name: e.lastname,
              email: e.email,
              phone: e.phone,
              gender: e.gender == "m" || e.gender == "M" ? 1 : 2,
              country: 'United Kingdom',
              city: e.city,
              address: e.street,
              post_code: e.post,
              gift_aid_declaration: e.declaration == 'yes' || e.declaration == 'Yes' || e.declaration == 'YES' ? 1 : 0,
          } 
          allUploaded+=1
          uploadPromises.push(newMember);
      });

      // Wait for all promises to resolve
      Promise.all(uploadPromises)
          .then(() => {
              if (allUploaded > 0) {
                  axios.post(urlbase + '/api/members/create/mass', {members:uploadPromises}, settings())
                  .then((res) => {
                      if (res.data.code == 200) {
                          //  setGivingAdded(1)
                          setAddingProcessing(0);
                           if (res.data.data.errors) {
                              alert(res.data.data.count + ' record(s) got uploaded successfully but with some errors: '+res.data.data.errors);
                              window.location.reload();
                          }else{
                              alert(res.data.data.count + ' record(s) got uploaded successfully');
                              window.location.reload();
                          }
                      } else {
                          setAddingProcessing(0);
                          alert(res.data.message)
                          console.log(res.data.message)
                      }
                  })
                  .catch(() => {
                      setAddingProcessing(0);
                      console.log('There was an error, please try again later');
                  });

              } else {
                  setAddingProcessing(0);
                  alert('No uploads could be completed, please ensure you are using the correct format');
                  setAddingProcessing(0);
              }
          })
          .catch(error => {
              console.error('Error uploading records:', error);
              alert('An error occurred during upload. Please check your network connection and try again.');
              setAddingProcessing(0);
          });

  }catch(e){
      alert('There was an error, please ensure your upload data is correct')
  }
}
  return (
    <>

{entryType ?
      <div id="defaultModal" tabIndex="-1" aria-hidden="true" className="fixed top-0 left-0 right-0 h-screen bg-black bg-opacity-50 inset-0 items-center justify-center z-50 w-full p-4 overflow-x-hidden overflow-y-auto md:inset-0 h-[calc(100%-1rem)] max-h-full">
      <div className="relative w-[90%] lg:w-[40%] mt-[25%] lg:mt-[10%] max-h-full mx-auto text-[#333]">
          <div className="relative bg-white shadow pt-3 rounded-[8px]">
              <div className="flex items-start justify-between border-b-[1px] px-8 pb-3">
                  <h3 className="text-[#121927] text-[20px] font-[500]">
                      Add new member
                  </h3>
                  <button onClick={() => setEntryType(0)} type="button" className="bg-[#F2F2F2] hover:bg-[#F2F2F2] text-black rounded-full p-1.5 ml-auto inline-flex items-center" data-modal-hide="defaultModal">
                      <svg aria-hidden="true" className="w-3 h-3" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
                      <span className="sr-only">Close modal</span>
                  </button>
              </div>
              <div className='flex flex-col space-y-3 mt-3 mb-10'>
                <div className="grid flex font-[300] flex-col text-[#040404] text-[16px] text-start space-y-2 px-8">
                    <p>Select how you want to add member</p>
                </div>
                <div onClick={() => setEntryType(2)} className={`flex flex-row mx-8 py-4 px-3 border-[1px] ${entryType == 2 ? 'border-[#B30000]' : ''} rounded-[8px] items-center space-x-5 cursor-pointer`}>
                    <div className=''><img src={individual} /></div>
                    <div className='flex flex-col'> 
                      <p className='text-[14px] font-[400]'>Individual member</p>
                      <p className='text-[#828282] text-[14px]'>Allows you to add single member  details to the system</p>
                    </div>
                </div>
                <div onClick={() => setEntryType(3)} className={`flex flex-row mx-8 py-4 px-3 border-[1px] ${entryType == 3 ? 'border-[#B30000]' : ''} rounded-[8px] items-center space-x-5 cursor-pointer`}>
                    <div className=''><img src={memberupload} /></div>
                    <div className='flex flex-col'>
                      <p className='text-[14px] font-[400]'>Upload member's data</p>
                      <p className='text-[#828282] text-[14px]'>Upload a file (CSV/Excel) consisting of members. </p>
                    </div>
                </div>
              </div>
              <div className="flex flex-row justify-end space-x-2 px-8 bg-[#F7F7F7] py-4 rounded-b-[8px]">
                  <button onClick={() => setEntryType(0)} data-modal-hide="defaultModal" type="button" className="border-[1px] border-[#828282] w-[102px] h-[50px] rounded-[8px]">Cancel</button>
                  <button onClick={makeSelection} disabled={!entryType || entryType == 1 ? true : false} data-modal-hide="defaultModal" type="button" className={`border-[1px] w-[119px] h-[50px] ${!entryType || entryType == 1 ? 'bg-[#E0E0E0]' : 'bg-[#B30000]'} rounded-[8px] border-none text-white`}>Continue</button>
              </div>
          </div>
      </div>
  </div>
  :null}

{uploadMem ?
      <div id="defaultModal" tabIndex="-1" aria-hidden="true" className="fixed top-0 left-0 right-0 h-screen bg-black bg-opacity-50 inset-0 items-center justify-center z-50 w-full p-4 overflow-x-hidden overflow-y-auto md:inset-0 h-[calc(100%-1rem)] max-h-full">
      <div className="relative w-[90%] lg:w-[33%] mt-[25%] lg:mt-[10%] max-h-full mx-auto text-[#333]">
          <div className="relative bg-white shadow pt-3 rounded-[8px]">
              <div className="flex items-start justify-between border-b-[1px] px-4 pb-3">
                  <h3 className="text-[#121927] text-[20px] font-[500]">
                      Upload new members
                  </h3>
                  <button onClick={() => setUploadMem(0)} type="button" className="bg-[#F2F2F2] hover:bg-[#F2F2F2] text-black rounded-full p-1.5 ml-auto inline-flex items-center" data-modal-hide="defaultModal">
                      <svg aria-hidden="true" className="w-3 h-3" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
                      <span className="sr-only">Close modal</span>
                  </button>
              </div>
              <div className='flex flex-col space-y-3 mt-3 mb-10'>
                <div className="grid flex font-[300] flex-col text-[#040404] text-[16px] text-start space-y-2 px-4">
                    <p>Upload your members</p>
                    <span className='text-[14px]'>Download sample format <Link target='_blank' to="/MembersUploadFormat.xlsx" download className='text-[#B30000] cursor-pointer'>here.</Link></span>
                </div>
                <div onClick={selectFile} className='col-span-6 mx-4 flex flex-col space-y-2 text-[#505050] border-dashed border-[1px] flex flex-col items-center py-7 rounded-[8px] cursor-pointer'>
                    <span><img className='w-[60%]' src={upload} /></span>
                    <span className='text-[14px]'>Upload your file (Max of {sessionStorage.getItem('chgamsSubscription') == 4 || sessionStorage.getItem('chgamsSubscription') == 5 ?  '100' : '70' } records) <span className='text-[#B30000]'>here</span></span>
                    <span className='text-[#828282] text-[14px]'>CSV or Excel Files only</span>
                </div>
                <input accept='.csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel' onChange={fileChange} id="file" hidden type='file' className='' />
                {fileChosen ?
                <div className='mx-4 col-span-6 rounded-[8px] border-[1px] flex flex-row p-3 items-center justify-between'>
                        <div className='flex flex-row items-center space-x-2'>
                            <span><img src={file} /></span>
                            <span>{theFile.name}</span>
                        </div>
                        <span onClick={removeFile} title='remove' className='cursor-pointer'><img src={remove} /></span>
                    </div>
                  : ''}
                 {error ? <span className='text-[14px] text-[#B30000]'>{error}</span> : null}
              </div>
              <div className="flex flex-row justify-end space-x-2 px-8 bg-[#F7F7F7] py-4 rounded-b-[8px] items-center">
                  <button onClick={() => setUploadMem(0)} data-modal-hide="defaultModal" type="button" className="border-[1px] border-[#828282] w-[102px] h-[50px] rounded-[8px]">Cancel</button>
                  {addingProcessing ? <p>Please wait</p> : <button onClick={() => processAdding()} data-modal-hide="defaultModal" type="button" className={`border-[1px] w-[119px] h-[50px] bg-[#B30000] rounded-[8px] border-none text-white`}>Continue</button>}
              </div>
          </div>
      </div>
  </div>
  :null}

{membersAdded ?
  <div id="defaultModal" tabIndex="-1" aria-hidden="true" className="fixed top-0 left-0 right-0 h-screen bg-black bg-opacity-50 inset-0 items-center justify-center z-50 w-full p-4 overflow-x-hidden overflow-y-auto md:inset-0 h-[calc(100%-1rem)] max-h-full">
      <div className="relative w-[50%] lg:w-[48%] mt-20 max-h-full mx-auto text-[#333]">
          <div className="relative bg-white shadow rounded-[8px] px-8 py-10">
              <div className="flex items-start justify-between px-8">
                  <h3 className="text-[#121927] text-[20px] font-[600]">
                  </h3>
              </div>
              <div className='flex flex-col space-y-2 mt-3 px-5 text-center'>
                <div className='grid mb-2'><img className='mx-auto w-[30%]' src={success} /></div>
                <span className='text-[#121927] font-[500] text-[24px]'>Awesome! New member has beeen added successfully</span>
                <span className='text-[#4F4F4F] text-[16px] font-[300]'>Member's Login credentials has been sent to their email address. They can update after login</span>
              </div>
              <div className="flex flex-row mt-5 space-x-3 px-8 pb-4 justify-center">
                  <Link to={'/'+base+"/dashboard"} data-modal-hide="defaultModal" type="button" className="border-[1px] w-full h-[50px] bg-white rounded-[8px] border-[1px] text-[#121927] grid my-auto text-center"><p className="my-auto">Go to Dashboard</p></Link>
                  <button onClick={() => {window.location.reload()}} data-modal-hide="defaultModal" type="button" className="border-[#828282] w-full h-[50px] rounded-[8px] bg-[#B30000] text-white grid"><p className="my-auto">View Members Details</p></button>
              </div>
          </div>
      </div>
  </div>
  :null}
    {deleteModal ?
      <div id="defaultModal" tabIndex="-1" aria-hidden="true" className="fixed top-0 h-screen bg-black bg-opacity-50 inset-0 items-center justify-center z-50 left-0 right-0 w-full p-4 overflow-x-hidden overflow-y-auto md:inset-0 h-[calc(100%-1rem)] max-h-full">
          <div className="relative w-[50%] lg:w-[35%] mt-[10%] max-h-full mx-auto text-[#333]">
              <div className="relative bg-white shadow pt-2 rounded-[8px]">
                  <div className="flex items-start justify-between px-8 pb-4">
                      <h3 className="text-[#121927] text-[20px] font-[600]">
                      </h3>
                      <button onClick={() => setDeleteModal(0)} type="button" className="text-gray-300 font-[200] bg-[#F2F2F2] hover:text-black rounded-full p-1.5 ml-auto inline-flex items-center" data-modal-hide="defaultModal">
                          <svg aria-hidden="true" className="w-5 h-5 " fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
                          <span className="sr-only">Close modal</span>
                      </button>
                  </div>
                  <div className='flex flex-col space-y-4 mt-1 px-5 text-center'>
                    <div className='grid'><IoMdExit className='mx-auto' size={70}></IoMdExit></div>
                    <span className='text-[#121927] font-[500] text-[24px]'>Exit Member</span>
                    <span className='text-[12px] text-[#B30000]'>Exited members will be removed 2 years after their exit date along with their donations so claims can be made for their donations in the current financial year. You may delete their donations if you don't want them to reflect.</span>
                  </div>
                  <div className="flex flex-col mt-3 mb-3 space-y-3 px-8 py-4">
                    {deleteProcessing ? 
                      <LineWave height="100" width="100" color="#B30000" ariaLabel="line-wave" wrapperStyle={{marginTop:'-40px'}}
                      wrapperClass="" visible={true} firstLineColor="" middleLineColor="" lastLineColor=""/>
                     : <>
                     <div className='flex flex-col lg:flex-row lg:space-x-3 pb-3'>
                      <button onClick={() => setDeleteModal(0)} data-modal-hide="defaultModal" type="button" className="border-[#828282] w-full h-[50px] rounded-[8px] bg-[#F7F7F7] text-[#333333]">Cancel</button>
                      <button onClick={performDelete} data-modal-hide="defaultModal" type="button" className="border-[1px] w-full h-[50px] bg-[#EB5757] rounded-[8px] border-none text-white">Continue</button>
                     </div>
                    </>
                    }

                  </div>
              </div>
          </div>
      </div>
      :null}

    {add ?
      <div id="defaultModal" tabIndex="-1" aria-hidden="true" className="fixed top-0 left-0 right-0 h-screen bg-black bg-opacity-50 inset-0 items-center justify-center z-50 w-full p-4 overflow-x-hidden overflow-y-auto md:inset-0 h-[calc(100%-1rem)] max-h-full">
          <div className="relative w-[90%] lg:w-[60%] mt-[10%] lg:mt-[1%] max-h-full mx-auto text-[#333]">
              <form onSubmit={PerformAdd} className="relative bg-white shadow pt-3 rounded-[8px]">
                  <div className="flex items-start justify-between border-b-[1px] px-8 pb-3">
                      <h3 className="text-[#121927] text-[20px] font-[600]">
                          Add new member
                      </h3>
                      <button onClick={(e) => setAdd(0)} type="button" className="text-gray-300 font-[200] bg-transparent hover:bg-[#F2F2F2] hover:text-black rounded-full p-1.5 ml-auto inline-flex items-center" data-modal-hide="defaultModal">
                          <svg aria-hidden="true" className="w-5 h-5 " fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
                          <span className="sr-only">Close modal</span>
                      </button>
                  </div>
                  <div className='mt-7 text-start px-8 text-[#292D32] font-[500] text-[18px]'>Personal Information</div>
                  <div className='grid grid-cols-6 px-8 mt-5 gap-x-4 gap-y-5'>
                    <div className="col-span-6 lg:col-span-2 grid flex flex-col text-start space-y-2">
                        <div className='text-[#505050]'>First Name <span className='text-[#B30000]'>*</span></div>
                        <input onChange={(e) => AddMemberData(e.target.value,'first_name')} required className='border-[1px] rounded-[8px] p-3 outline-none text-[#4F4F4F] h-[50px]' placeholder='Enter first name'/>
                    </div>
                    <div className="col-span-6 lg:col-span-2 grid flex flex-col text-start space-y-2">
                        <div className='text-[#505050]'>Last Name <span className='text-[#B30000]'>*</span></div>
                        <input onChange={(e) => AddMemberData(e.target.value,'last_name')} required className='border-[1px] rounded-[8px] p-3 outline-none text-[#4F4F4F] h-[50px]' placeholder='Enter last name'/>
                    </div>
                    <div className="col-span-6 lg:col-span-2 grid flex flex-col text-start space-y-2">
                        <div className='text-[#505050]'>Phone Number <span className='text-[#B30000]'>*</span></div>
                        <input onChange={(e) => AddMemberData(e.target.value,'phone')} required className='border-[1px] rounded-[8px] p-3 outline-none text-[#4F4F4F] h-[50px]' placeholder='Enter phone number'/>
                    </div>
                    <div className="col-span-6 lg:col-span-2 grid flex flex-col text-start space-y-2">
                        <div className='text-[#505050]'>Email <span className='text-[#B30000]'>*</span></div>
                        <input type='email' onChange={(e) => AddMemberData(e.target.value,'email')} required className='border-[1px] rounded-[8px] p-3 outline-none text-[#4F4F4F] h-[50px]' placeholder='Enter email addresss'/>
                        <span className="text-[12px] text-[#B30000]">Please make sure this email is valid</span>
                    </div>
                    <div className="col-span-6 lg:col-span-2 flex flex-col text-start space-y-2">
                        <div className='text-[#505050]'>Gender <span className='text-[#B30000]'>*</span></div>
                        <select onChange={(e) => AddMemberData(e.target.value,'gender')} required className='border-[1px] rounded-[8px] p-3 outline-none text-[#4F4F4F] w-[100%] h-[50px]'>
                          <option value='0'>Select gender</option>
                          <option value='1'>Male</option>
                          <option value='2'>Female</option>
                        </select>
                    </div>
                    <div className="col-span-6 lg:col-span-2 flex flex-col text-start space-y-2">
                        <div className='text-[#505050]'>Date of Birth</div>
                        <input max={today} onChange={(e) => AddMemberData(e.target.value,'birthday')} type='date' className='border-[1px] rounded-[8px] p-3 outline-none text-[#4F4F4F] h-[50px]'/>
                    </div>
                    <div className="col-span-6 lg:col-span-2 flex flex-col text-start space-y-2">
                        <div className='text-[#505050]'>Group</div>
                        <select onChange={(e) => AddMemberData(e.target.value,'group_ids')} className='border-[1px] rounded-[8px] p-3 outline-none text-[#4F4F4F] w-[100%] h-[50px]'>
                          <option value="choose">Select group</option>
                          {groups.length > 0 ? 
                            <>
                            {groups.map(({id,name}) => {
                              return (
                                <option key={id} value={id}>{name}</option>
                              )
                            })}</>
                             : null
                          }
                          
                        </select>
                    </div>
                    <div className="col-span-6 lg:col-span-2 flex flex-col text-start space-y-2">
                        <div className='text-[#505050]'>Wedding date</div>
                        <input max={today} onChange={(e) => AddMemberData(e.target.value,'wedding_date')} type='date' className='border-[1px] rounded-[8px] p-3 outline-none text-[#4F4F4F] h-[50px]'/>
                    </div>
                    <div className="col-span-6 lg:col-span-2 flex flex-col text-start space-y-2">
                      <label className='text-[#505050]'>Gift aid declaration</label>
                      <div className='flex flex-row items-center space-x-2'>
                        <input onChange={(e) => AddMemberData(e.target.value,'gift_aid_declaration')} type='checkbox' className='border-[1px] rounded-[8px] p-3 outline-none text-[#4F4F4F] h-[50px] accent-[#B30000]'/>
                        <div className='text-[#828282]'>Declare gift aid</div>
                      </div>
                    </div>
                  </div>

                  <div className='mt-7 text-start px-8 text-[#292D32] font-[500] text-[18px]'>Address</div>
                  <div className='grid grid-cols-6 px-8 mt-5 gap-x-3 gap-y-3'>
                    <div className="col-span-6 lg:col-span-2 grid flex flex-col text-start space-y-2">
                        <div className='text-[#505050]'>Country</div>
                        <input onChange={(e) => AddMemberData(e.target.value,'country')} defaultValue='United Kingdom' readOnly className='border-[1px] rounded-[8px] p-3 outline-none text-[#4F4F4F] h-[50px]' placeholder='Input country'/>
                    </div>
                    <div className="col-span-6 lg:col-span-2 grid flex flex-col text-start space-y-2">
                        <div className='text-[#505050]'>City <span className='text-[#B30000]'>*</span></div>
                        <input onChange={(e) => AddMemberData(e.target.value,'city')} required className='border-[1px] rounded-[8px] p-3 outline-none text-[#4F4F4F] h-[50px]' placeholder='Input city'/>
                    </div>
                    <div className="col-span-6 lg:col-span-2 grid flex flex-col text-start space-y-2">
                        <div className='text-[#505050]'>Post code <span className='text-[#B30000]'>*</span></div>
                        <input onChange={(e) => AddMemberData(e.target.value,'post_code')} required className='border-[1px] rounded-[8px] p-3 outline-none text-[#4F4F4F] h-[50px]' placeholder='Enter post code'/>
                    </div>
                    <div className="col-span-6 lg:col-span-6 grid flex flex-col text-start space-y-2">
                        <div className='text-[#505050]'>Street Address <span className='text-[#B30000]'>*</span></div>
                        <textarea onChange={(e) => AddMemberData(e.target.value,'address')} required className='border-[1px] rounded-[8px] p-3 outline-none text-[#4F4F4F] h-[76px]'></textarea>
                    </div>
                  </div>
                  <div className="flex flex-row justify-end mt-5 space-x-2 px-8 bg-[#F7F7F7] py-4 rounded-b-[8px]">
                      <button onClick={() => setAdd(0)} data-modal-hide="defaultModal" type="button" className="border-[1px] border-[#828282] w-[102px] h-[50px] rounded-[8px]">Cancel</button>
                      {addingProcessing ? <LineWave height="100" width="100" color="#B30000" ariaLabel="line-wave" wrapperStyle={{marginTop:'-40px'}}
                        wrapperClass="" visible={true} firstLineColor="" middleLineColor="" lastLineColor=""/>
                        : <button data-modal-hide="defaultModal" type="submit" className="border-[1px] w-[178px] h-[50px] bg-[#B30000] rounded-[8px] border-none text-white">Add new member</button>}
                  </div>
              </form>
          </div>
      </div>
      :null}
      {!fetching ? 
      <div className={`grid grid-cols-1 mt-5 mb-5 px-8 ${add || deleteModal || membersAdded ? 'opacity-25' : ''}`}>
        <div id="members" className='col-span-1 flex lg:flex-row flex-col justify-between space-y-4 lg:space-y-0'>
          <p className='text-[#292D32] text-[24px] font-[600] text-start'>All Members</p>
          <div className='flex flex-col lg:flex-row space-y-3 lg:space-y-0 lg:space-x-4'>
            {sessionStorage.getItem('role') == 1 && membersData.length > 0? 
          <CSVLink filename={"members.csv"} data={membersData} headers={membersheaders}>
            <div className='border-[1px]  h-[40px] px-3 cursor-pointer rounded-[4px] flex flex-row p-2 items-center space-x-3'>
              <img src={download} />
              <p className='text-[#292D32] text-[15px] font-[600]'>Download CSV</p>
            </div>
            </CSVLink> : null } 
            {sessionStorage.getItem('role') != 1 && sessionStorage.getItem('role') != 2 ? null :
            <div onClick={() => memberAddType()} className='border-[1px] bg-[#B30000] px-2 h-[40px] cursor-pointer rounded-[4px] flex flex-row p-2 items-center space-x-3'>
              <img src={give} />
              <p className='text-white text-[15px] font-[600]'>Add Member</p>
            </div>}
          </div>
          
        </div>
        <div className='col-span-1 flex lg:flex-row flex-col space-y-4 lg:space-y-0 justify-between mt-8'>
          <div className='relative'>
            <div className='absolute top-3 z-40 left-4'><img src={search} /></div>
            <input onChange={(e) => setSearchItem(e.target.value)} className='relative w-[321px] bg-[#F7F7F7] h-[40px] pl-10 rounded-[8px] border-[1px] text-[#8A8A8A] outline-none' placeholder='Search' />
          </div>
          <div className='flex flex-row space-x-4 items-center'>
            <div className=''>
              {/* <div className='absolute '></div> */}
              <input onChange={(e) => dateFilter(e.target.value)} className='relative border-[1px] h-[40px] rounded-[4px] outline-none border-[#D9D9D9] p-1' type='date' />
            </div>
            <div>
              <div onClick={(e) => filterAction(e)} className='flex flex-row space-x-3 border-[1px] rounded-[4px] p-1 px-3 h-[40px] cursor-pointer'>
                <img src={filter} />  
                <span>Filter</span>
              </div>
              {filterr && ( 
              <div ref={filterRef} className='absolute rounded-[4px] flex flex-col z-50 bg-white space-y-3 shadow-md right-10 py-2'>
                {MembersTypes.map((item,id) => {
                  return (
                    <div key={id} className='flex flex-row space-x-2 px-4 cursor-pointer hover:bg-[#F7F7F7] py-2'>
                      <input type='checkbox' className='accent-[#B30000]' name={item}
                        checked={item == 'Female' ? female : male}
                        onChange={(e) => handleCheckboxChange(e,item)}
                        />
                      <p>{item}</p>
                    </div>
                  )
                })}
              </div>
              )}
            </div>

          </div>
        </div>
         <>
        <div className='col-span-1 mt-5 border-[1px] rounded-[8px] overflow-x-scroll relative'>
          <table className="table-auto block divide-y divide-gray-200 relative z-10">
            <thead className='bg-[#F7F7F7]'>
                <tr className='text-[#292D32] font-[400]'>
                {/* <th className='px-6 py-3 text-star'><input type='checkbox' className='accent-[#B30000]' /></th> */}
                <th className='px-6 py-1 text-start text-[#292D32] font-[400] w-[16.6666667%]'>Name</th>
                <th className='px-6 py-1 text-start text-[#292D32] font-[400] w-[16.6666667%]'>Member ID</th>
                <th className='px-6 py-1 text-start text-[#292D32] font-[400] w-[16.6666667%]'>Email Address</th>
                <th className='pl-6 py-1 text-start text-[#292D32] font-[400] w-[16.6666667%]'>Phone Number</th>
                <th className='px-6 py-1 text-start text-[#292D32] font-[400] w-[16.6666667%]'>Gender</th>
                {sessionStorage.getItem('role') != 1 ? null :
                <th className='px-6 py-1 text-start text-[#292D32] font-[400] w-[16.6666667%] text-[#F7F7F7]'>Action</th>}
                </tr>
            </thead>
            {memberData.length < 1 ? 
              <div className=''>
                No members retrieved
              </div> : 
            <tbody className='divide-y divide-gray-200'>
                {MembersCurrent.filter((row) =>
                !searchItem.length || row.name
                .toString()
                .toLowerCase()
                .includes(searchItem.toString().toLowerCase()) 
                ).map(({id,name,member_id,gender,email,phone},index) => {
                    return (
                        <tr key={id}>
                            {/* <td className='px-6 py-3 text-star'><input type='checkbox' className='accent-[#B30000]' /></td> */}
                            <td className='px-6 py-1 text-start text-[#4F4F4F]'>{name}</td>
                            <td className='px-6 py-1 text-start text-[#4F4F4F]'>{member_id}</td>
                            <td className='px-6 py-1 text-start text-[#4F4F4F]'>{email}</td>
                            <td className='px-6 py-1 text-start text-[#4F4F4F]'>{phone}</td>
                            <td className='px-6 py-1 text-start text-[#4F4F4F]'>{gender == 1 ? 'Male' : 'Female'}</td>
                            {sessionStorage.getItem('role') != 1 ? null :
                            <td className='px-6 py-3 text-start text-[#4F4F4F] relative'>
                              <span onClick={(e) => changeActiveDon(e,index)} className='relative cursor-pointer'><img className='' src={more} /></span>
                              {activemembers == index ? 
                              
                              // <span ref={filterRef2} className='absolute lg:fixed w-[140px] bg-white shadow-md rounded-[8px] left-[-60%] lg:left-[85%] items-center justify-center z-50 w-full px-0 py-2'>
                              <span ref={filterRef2} className='absolute right-10 w-full z-50 py-2 shadow-md rounded-[8px] bg-white flex flex-col'>
                                <span onClick={() => setTheDelete(id)} className='px-4 py-2 cursor-pointer hover:bg-[#F7F7F7] flex flex-row items-center space-x-3 text-[12px]'>
                                  <span><IoMdExit></IoMdExit></span>
                                  <span>Exit member</span>
                                </span>
                                <span onClick={() => viewDetails(id)} className='px-4 py-2 cursor-pointer hover:bg-[#F7F7F7] flex flex-row items-center space-x-3 text-[12px]'>
                                  <span><img src={view} /></span>
                                  <span>View Details</span>
                                </span>
                              </span> : null
                              }
                            </td> } 
                        </tr>
                        )
                    }) 
                }
            </tbody>
            }
          </table>
        </div>
        <div className='col-span-1 flex flex-row justify-between items-center'>
          {searchItem? '' : <span className='text-[#333333] text-[16px] text-start'>Showing <span>{pageCount}</span>/<span>{membersData.length}</span> results</span>}
          <div className={`ml-auto grid grid-cols-6 gap-x-4`}>
                <div className={`mt-5 col-span-6 flex flex-row gap-x-3 mb-5 lg:ml-auto items-center`}>
                    <div onClick={PreviousPage} className={`border-[#BCBCBC] h-[40px] lg:h-auto max-h-[46px] w-[35px] lg:w-auto border-[1px] px-2 lg:px-4 py-1 lg:py-2 rounded-[6px] items-center text-[#BCBCBC] hover:text-[#1D242D] cursor-pointer`}>{`<`}</div>
                    {MembersArray.length > 5 ? <span className={`cursor-pointer text-[14px] font-[500] ${currentPage > 0 ? 'text-[#B30000]' : ''}`} onClick={FirstPage} >First</span> : null }
                    {MembersArray.slice(tableStart,tableEnd).map((MembersArrayS,index)=> {
                        return (
                            <div key={index} onClick={()=>numberedPage(MembersArray.indexOf(MembersArrayS))} className={`transitiion-all h-[40px] lg:h-auto max-h-[46px] w-[35px] lg:w-auto text-start duration-500 border-[#BCBCBC] font-[600] text-[18px] border-[1px] px-2 lg:px-4 py-1 lg:py-2 rounded-[6px] items-center ${currentPage == MembersArray.indexOf(MembersArrayS) ? 'text-white bg-[#B30000] border-[#B30000] hover:bg-[#B30000] hover:text-white' :'text-[#292D32] bg-white hover:text-white hover:bg-[#B30000]'} hover:text-[#1D242D] cursor-pointer`}>{MembersArray.indexOf(MembersArrayS)+1}</div>
                        )
                    })}
                    {MembersArray.length > 5 ? <span className={`cursor-pointer text-[14px] font-[500] ${currentPage < MembersArray.length-1 ? 'text-[#B30000]' : ''}`} onClick={LastPage}>Last</span> : null }
                    <div onClick={NextPage} className={`border-[#BCBCBC] border-[1px] lg:h-auto max-h-[46px] w-[35px] lg:w-auto px-2 lg:px-4 py-1 lg:py-2 rounded-[6px] items-center text-[#BCBCBC] hover:text-[#1D242D] cursor-pointer`}>{`>`}</div>
                </div>
            </div>
        </div>
          </> 
      </div>
      : <div className='col-span-8 lg:col-span-6 flex flex-col justify-center items-center '>
      <ThreeDots height="80" width="80" radius="9" color="#333333" ariaLabel="three-dots-loading"
      wrapperStyle={{}} wrapperClassName="" visible={true}
      />
      </div>}
    </>
  )
}

export default Members