import React, {useState,useEffect} from 'react'
import search from "../../../images/icons/search.svg"
import { ThreeDots } from 'react-loader-spinner'
import filter from "../../../images/icons/filter.svg"
import HistorySingle from './historySingle'
import axios from 'axios'
import { LineWave } from  'react-loader-spinner'
import settings from '../../../components/getrequest'
import { format } from 'date-fns';
import delet from "../../../images/icons/delete2.svg"
import edit from "../../../images/icons/edit.svg"
import alert from "../../../images/icons/alert.svg"
import {AiOutlineEye,AiOutlineEyeInvisible} from 'react-icons/ai'
import success from "../../../images/success_green.svg"
import deleteRed from "../../../images/icons/deleteRed.svg"
import { MdNextPlan } from "react-icons/md";

function ClaimDraft() {
  const [base, setBase] = useState(sessionStorage.getItem('prefix') ? sessionStorage.getItem('prefix'): 'user')
  let urlbase = process.env.REACT_APP_BASE;
  const [error, setError] = useState(0)
  const [processing, setProcessing] = useState(0)
    const claimsheaders = [ 
        { label: "id", key: "id" },
        { label: "name", key: "name" },
        { label: "date", key: "date" },
        { label: "member_id", key: "member_id" },
        { label: "amount", key: "amount" },
        { label: "type", key: "type" },
        { label: "payment", key: "payment" },
      ]
    const [claimsData, setclaimsData] = useState([])
    const[fetching, setFetching] = useState(1)
    const sliceValue = 4
    const [givingArray, setgivingArray] = useState([])
    const[givingCurrent, setgivingCurrent] = useState([])
    const[givingCurrentDefault, setgivingCurrentDefault] = useState()
    const [currentPage,setCurrentPage] = useState(0)
    useEffect(()=>{
      //get running claims
      axios.get(urlbase+'/api/claims/history',settings())
      .then((res) => {
          if(res.data.code == 200){
            setclaimsData(res.data.data.draft)
            const allData = res.data.data.draft;
            const slicedData = [];
            for (let i = 0; i < allData.length; i += sliceValue) {
              slicedData.push(allData.slice(i, i + sliceValue));
            }
            setgivingCurrent(slicedData[0].length > 0 ?slicedData[0] : [])
            setgivingArray(slicedData);
          }else{
              setError(res.data.message)
              setProcessing(0)
          }
      }).catch((e) => {
          setError('There was an internal error, please try again later')
          setProcessing(0)
      })

    setFetching(0)
    },[])

      const PreviousPage = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
        setCurrentPage(currentPage > 0 ? currentPage-1 : currentPage)
        if(currentPage > 0){
          setgivingCurrent(givingArray[currentPage-1])
          setgivingCurrentDefault(givingArray[currentPage-1])
        }
      }
      const NextPage = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
        setCurrentPage(currentPage == givingArray.length-1 ? currentPage : currentPage+1)
        if(currentPage == givingArray.length-1){
      
        }else{
          setgivingCurrent(givingArray[currentPage+1])
          setgivingCurrentDefault(givingArray[currentPage+1])
        }
      }
      const numberedPage = (id) => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
        setCurrentPage(id)
        setgivingCurrent(givingArray[id])
        setgivingCurrentDefault(givingArray[id])
      }
      const [searchItem, setSearchItem] = useState("");
      //Active donation
      const [activeDonation, setActiveDonation] = useState()
      const changeActiveDon = (id) => {
        if(activeDonation == id) setActiveDonation(null)
        else setActiveDonation(id)
      }
      
      //giving types
      const givingTypes = ['Cash','Individual','Aggregated','GASDS']
      
      //filter
      const [filterr, setFilterr] = useState()
      const filterAction = () => {
        if(filterr) setFilterr(0)
        else setFilterr(1)
      }

    const [singleHistory, setSingleHistory] = useState()

    function changeToSingle(){
        setSingleHistory(1)
    }

    const [initiate, setInitiate] = useState()
    const [summaryModal, setSummaryModal] = useState()
    const [acceptTerms, setAcceptTerms] = useState()
    const [governInfo, setGovernInfo] = useState()
    const [submitted, setSubmitted] = useState()
    const [termsAccepted, setTermsAccepted] = useState()

    const [claim_draft, setClaimDraft] = useState({type:'draft'})
    const [addingProcessing,setAddingProcessing] = useState()
    const AddDraftData = (field,value) => {
      setClaimDraft({...claim_draft, [field]:value})
    }
    const [claimCreated, setClaimCreated] = useState()

    const PerformClaimSubmit = () => {
      setAddingProcessing(1)
      axios.post(urlbase+'/api/claims/create',claim_draft,settings())
      .then((res) => {
          if(res.data.code == 200){
            setInitiate(0)
            setClaimCreated(1)
          }else{
              window.alert(res.data.message);
              setInitiate(0)
          }
          setAddingProcessing(0)
      }).catch(() => {
          window.alert('There was an error, please try again');
          setAddingProcessing(0)
      })
    }
    function termsModal(){
      setSummaryModal(0)
      setAcceptTerms(1)
    }
    
    function governInfoModal(){
      setAcceptTerms(0)
      setTermsAccepted(0)
      setGovernInfo(1)
    }
    const [claimId, setClaimId] = useState()
    const [claimDetails, setClaimDetails] = useState({claim_id:claimId,type:'claim'})
    function processSubmission(){
      PerformClaim()
    }

    function setSummary(id){
      sessionStorage.setItem('claim_id',id)
      setClaimCreated(0)
      getSummary()
    }    

    const [claimSummary, setClaimSummary] = useState({})

  const getSummary = () => {
    setAddingProcessing(1)
    axios.get(urlbase+'/api/claims/summary/'+sessionStorage.getItem('claim_id'),settings())
    .then((res) => {
        if(res.data.code == 200){
          setClaimSummary(res.data.data?res.data.data:{})
          setClaimCreated(0)
          setSummaryModal(1)
        }else{
            window.alert(res.data.message);
            setInitiate(0)
        }
        setAddingProcessing(0)
    }).catch(() => {
        window.alert('There was an error, please try again');
        setAddingProcessing(0)
    })
  }
    
    const PerformClaim = () => {
      claimDetails.claim_id = sessionStorage.getItem('claim_id')
      setAddingProcessing(1)
      axios.post(urlbase+'/api/claims/create',claimDetails,settings())
      .then((res) => {
          if(res.data.code == 200){
            setGovernInfo(0)
            setInitiate(0)
            setClaimCreated(1)
            setSubmitted(1)
          }else{
              window.alert(res.data.message);
              setInitiate(0)
          }
          setAddingProcessing(0)
      }).catch(() => {
          window.alert('There was an error, please try again');
          setAddingProcessing(0)
      })
    }
    
    function cancelProcess(){
      setClaimCreated(0)
      sessionStorage.setItem('draft',1)
      window.location.href='/claims'
    }
    const [see,setSee] = useState()
    const AddClaim = (e,field) => {
      setClaimDetails({...claimDetails, [field]:e.target.value})
    }

    const accepting = (e) =>{
      if(e.target.checked){
        setTermsAccepted(1)
      }else{
        setTermsAccepted(0)
      }
    }

    const cancelAccepting = () => {
      setAcceptTerms(0)
      setTermsAccepted(0)
    }

    //Delete Draft
    const [deleteDraft, setDeleteDraft] = useState();
    const [deleting, setDeleting] = useState()

    const makeDraftDelete = () => {
      setDeleting(1)
      axios.delete(urlbase+'/api/claims/'+deleteDraft, settings())
        .then((res) => {
          if (res.data.code === 200) {
            window.alert('Draft deleted successfully')
            window.location.reload();
          } else {
            window.alert(res.data.message);
            setDeleting(0)
          }
        })
        .catch((e) => {
          window.alert('This process cannot be carried out at this time, please try again later');
          setDeleting(0)
        });
    }
  return (
        <>
    {deleteDraft ?
      <div id="defaultModal" tabIndex="-1" aria-hidden="true" className="fixed top-0 left-0 right-0 h-screen bg-black bg-opacity-50 inset-0 items-center justify-center z-50 w-full p-4 overflow-x-hidden overflow-y-auto md:inset-0 h-[calc(100%-1rem)] max-h-full">
          <div className="relative w-[50%] lg:w-[35%] mt-[6%] max-h-full mx-auto text-[#333]">
              <div className="relative bg-white shadow pt-4 rounded-[8px]">
                  <div className="flex items-start justify-between px-8 pb-4">
                      <h3 className="text-[#121927] text-[20px] font-[600]">
                      </h3>
                      <button onClick={() => setDeleteDraft(0)} type="button" className="text-gray-300 font-[200] bg-[#F2F2F2] hover:text-black rounded-full p-1.5 ml-auto inline-flex items-center" data-modal-hide="defaultModal">
                          <svg aria-hidden="true" className="w-5 h-5 " fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clip-rule="evenodd"></path></svg>
                          <span className="sr-only">Close modal</span>
                      </button>
                  </div>
                  <div className='flex flex-col space-y-4 mt-3 px-5 text-center'>
                    <div className='grid mb-2'><img className='mx-auto' src={deleteRed} /></div>
                    <span className='text-[#121927] font-[600] text-[24px]'>Delete Claim Draft</span>
                    <span className='text-[#4F4F4F] text-[16px]'>Deleted draft cannot be retrieved, you would have to begin the claiming process again. Are you sure you want to delete?</span>
                  </div>
                  <div className="flex flex-col mt-5 space-y-3 px-8 py-4">
                  { deleting ? <LineWave height="100" width="100" color="#B30000" ariaLabel="line-wave" wrapperStyle={{marginTop:'-40px'}}
                      wrapperClass="" visible={true} firstLineColor="" middleLineColor="" lastLineColor=""/>
                       :
                      <>
                      <button onClick={makeDraftDelete} data-modal-hide="defaultModal" type="button" className="border-[1px] w-full h-[50px] bg-[#EB5757] rounded-[8px] border-none text-white">Yes, delete draft</button>
                      <button onClick={() => setDeleteDraft(0)} data-modal-hide="defaultModal" type="button" className="border-[#828282] w-full h-[50px] rounded-[8px] bg-[#F7F7F7] text-[#333333]">No, Cancel</button>
                      </> }
                  </div>
              </div>
          </div>
      </div>
      :null}
  {summaryModal ?
  <div id="defaultModal" tabIndex="-1" aria-hidden="true" className={`fixed top-0 left-0 right-0 h-screen bg-black bg-opacity-50 inset-0 items-center justify-center z-50 w-full p-4 overflow-x-hidden overflow-y-auto md:inset-0 h-[calc(100%-1rem)] max-h-full`}>
    <div className="relative w-[95%] lg:w-[40%] mt-[25%] lg:mt-[10%] max-h-full mx-auto text-[#333]">
        <div className="relative bg-white shadow pt-3 rounded-[8px]">
            <div className="flex items-start justify-between border-b-[1px] px-8 pb-3">
                <h3 className="text-[#121927] text-[20px] font-[600]">
                  Repayment Claim Summary
                </h3>
                <button onClick={() => setSummaryModal(0)} type="button" className="text-gray-300 font-[200] bg-transparent hover:bg-[#F2F2F2] hover:text-black rounded-full p-1.5 ml-auto inline-flex items-center" data-modal-hide="defaultModal">
                    <svg aria-hidden="true" className="w-5 h-5 " fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
                    <span className="sr-only">Close modal</span>
                </button>
            </div>
            <div className='flex flex-col mt-3 mb-20 px-6 text-start text-[#4F4F4F] text-[16px] space-y-3'>
              <div className='flex flex-row justify-between'>
                <span>Individual donations</span>
                <span className='text-[#121927]'>£{claimSummary.individual? claimSummary.individual.toLocaleString(): 0}</span>
              </div>
              <div className='flex flex-row justify-between'>
                <span>Aggregated donations</span>
                <span className='text-[#121927]'>£{claimSummary.aggregated ? claimSummary.aggregated.toLocaleString() : 0}</span>
              </div>
              <div className='flex flex-row justify-between'>
                <span>GASDS</span>
                <span className='text-[#121927]'>£{claimSummary.gasd ? claimSummary.gasd.toLocaleString() :0}</span>
              </div>
              <div className='flex flex-row justify-between'>
                <span>Adjustments</span>
                <span className='text-[#121927]'>£{claimSummary.adjustments ? claimSummary.adjustments.toLocaleString() : 0}</span>
              </div>
            </div>
            <div className='flex flex-col mt-[-35px] mb-20 px-6 text-start text-[#4F4F4F] text-[16px] space-y-3 border-t-[1px] border-b-[1px] py-4'>
              <div className='flex flex-row justify-between'>
                <span>Total to be recieved</span>
                <span className='text-[#121927] text-[20px] font-[600]'>£{claimSummary.tax_reclaimed ? claimSummary.tax_reclaimed.toLocaleString() : 0}</span>
              </div>
            </div>
            <div className="flex flex-row justify-end mt-5 space-x-2 px-8 bg-[#F7F7F7] py-4 rounded-b-[8px]">
                <button onClick={() => setSummaryModal(0)} data-modal-hide="defaultModal" type="button" className="border-[1px] border-[#828282] text-[#121927] w-[102px] h-[50px] rounded-[8px] bg-white font-[600]">Cancel</button>
                <button onClick={termsModal} data-modal-hide="defaultModal" type="button" className={`border-[1px] w-[150px] h-[50px] bg-[#B30000] rounded-[8px] border-none text-white font-[600]`}>Continue</button>
            </div>
        </div>
    </div>
    </div>
    :null} 
            {acceptTerms ?
          <div id="defaultModal" tabIndex="-1" aria-hidden="true" className={`fixed top-0 left-0 right-0 h-screen bg-black bg-opacity-50 inset-0 items-center justify-center z-50 w-full p-4 overflow-x-hidden overflow-y-auto md:inset-0 h-[calc(100%-1rem)] max-h-full`}>
          <div className="relative w-[95%] lg:w-[40%] mt-[25%] lg:mt-[10%] max-h-full mx-auto text-[#333]">
              <div className="relative bg-white shadow pt-3 rounded-[8px]">
                  <div className="flex items-start justify-between border-b-[1px] px-6 pb-3">
                      <h3 className="text-[#121927] text-[20px] font-[600]">
                        Submit Repayment Claim to HRMC
                      </h3>
                      <button onClick={cancelAccepting} type="button" className="text-gray-300 font-[200] bg-transparent hover:bg-[#F2F2F2] hover:text-black rounded-full p-1.5 ml-auto inline-flex items-center" data-modal-hide="defaultModal">
                          <svg aria-hidden="true" className="w-5 h-5 " fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
                          <span className="sr-only">Close modal</span>
                      </button>
                  </div>
                  <div className='flex flex-col mt-3 mb-20 px-6 text-start space-y-4 text-[#4F4F4F] text-[16px]'>
                    <div className=''>
                      <p className='text-[#121927] font-[600]'>{sessionStorage.getItem('enterprise')}</p>
                      <p className='mt-1'>This is the final step in the claim preparation process. Please indicate your agreement to the statement below.</p>
                    </div>
                    <div>
                      <p className='text-[#121927] font-[600]'>Declaration</p>
                      <p className='mt-1'>The information I have given in this claim is correct to the best of mh knowledge and belief.</p>
                      <p className='mt-1'>I confirm I have read the HRMC notes and guidance on claiming gift Aid and/or top-up payments under the Gift Aid Small Donation Schemes.</p>
                      <p className='mt-1'>Where this claim wholly or partly relates to Gift Aid, I claim exemption from tax for this charity for the period covered by the claim.</p>
                      <p className='mt-1'>Where this claim wholly or partly relates to Gift Aid, I claim exemption from tax for this charity for the period covered by the claim.</p>
                      <span className='mt-1 flex flex-row space-x-2'>
                        <input onChange={(e) => accepting(e)} type='checkbox' className='accent-[#B30000]'/>
                        <p>I understand that false statement can lead to prosecution.</p>
                      </span>
                    </div>
                  </div>
                  <div className="flex flex-row justify-end mt-5 space-x-2 px-8 bg-[#F7F7F7] py-4 rounded-b-[8px]">
                      <button onClick={() => setAcceptTerms(0)} data-modal-hide="defaultModal" type="button" className="border-[1px] border-[#828282] text-[#121927] w-[102px] h-[50px] rounded-[8px] bg-white font-[600]">Go back</button>
                      <button onClick={governInfoModal} disabled={!termsAccepted ? true : false} data-modal-hide="defaultModal" type="button" className={`border-[1px] w-[150px] h-[50px] ${termsAccepted ? 'bg-[#B30000]': 'bg-[#E0E0E0]'} rounded-[8px] border-none text-white font-[600]`}>Continue</button>
                  </div>
              </div>
          </div>
      </div>
      :null} 

  {governInfo ?
        <div id="defaultModal" tabIndex="-1" aria-hidden="true" className={`fixed top-0 left-0 right-0 h-screen bg-black bg-opacity-50 inset-0 items-center justify-center z-50 w-full p-4 overflow-x-hidden overflow-y-auto md:inset-0 h-[calc(100%-1rem)] max-h-full`}>
        <div className="relative w-[95%] lg:w-[40%] mt-[20%] lg:mt-[4%] max-h-full mx-auto text-[#333]">
            <div className="relative bg-white shadow pt-3 rounded-[8px]">
                <div className="flex items-start justify-between border-b-[1px] px-8 pb-3">
                    <h3 className="text-[#121927] text-[20px] font-[600]">
                        Government Gateway
                    </h3>
                    <button onClick={() => setGovernInfo(0)} type="button" className="text-gray-300 font-[200] bg-transparent hover:bg-[#F2F2F2] hover:text-black rounded-full p-1.5 ml-auto inline-flex items-center" data-modal-hide="defaultModal">
                        <svg aria-hidden="true" className="w-5 h-5 " fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
                        <span className="sr-only">Close modal</span>
                    </button>
                </div>
                <div className='flex flex-col mt-3 mb-20 px-6 text-start space-y-4 text-[#4F4F4F] text-[16px]'>
                  <div className='flex flex-col space-y-2'>
                    <label>User ID</label>
                    <input onChange={(e) => AddClaim(e,'username')} className='border-[1px] rounded-[8px] pl-2 h-[50px]' placeholder='Input user Id' />
                  </div>
                  <div className='flex flex-col space-y-2'>
                    <label>Password</label>
                    <div className='relative'>
                      <input type={see ? 'text' : 'password'} onChange={(e) => AddClaim(e,'password')} className='relative border-[1px] w-full rounded-[8px] pl-2 h-[50px]' placeholder='Enter password' />
                      <span onClick={() => setSee(!see)} className='absolute right-3 top-4 cursor-pointer'> {see ? <AiOutlineEye/> : <AiOutlineEyeInvisible/> }</span>
                    </div>
                  </div>
                  <div className='border-[1px] rounded-[8px] flex flex-row space-x-3 p-3'>
                    <span className='mt-[-8px]'><img className='w-[64px] h-[64px]' src={alert} /></span>
                    <span className='text-[14px]'>
                      <p>Be sure you enter your <span className='font-[500] text-[#121927'>Government Gateway</span> credentials above, not your Chgams login details! </p>
                      <p className='mt-2'>Please also note the Government Gateway password is <span className='font-[500] text-[#121927'>case sensitive.</span></p>
                    </span>
                  </div>
                </div>
                {addingProcessing ? <div className='justify-end text-right items-center'><p>Please wait</p></div> :
                <div className="flex flex-row justify-end mt-5 space-x-2 px-8 bg-[#F7F7F7] py-4 rounded-b-[8px]">
                    <button onClick={() => setGovernInfo(0)} data-modal-hide="defaultModal" type="button" className="border-[1px] border-[#828282] text-[#121927] w-[102px] h-[50px] rounded-[8px] bg-white">Cancel</button>
                    <button onClick={processSubmission} data-modal-hide="defaultModal" type="button" className={`border-[1px] w-[150px] h-[50px] bg-[#B30000] rounded-[8px] border-none text-white`}>Continue</button>
                </div>
                }
            </div>
        </div>
    </div>
    :null} 

  {submitted ?
        <div id="defaultModal" tabIndex="-1" aria-hidden="true" className={`fixed top-0 left-0 right-0 h-screen bg-black bg-opacity-50 inset-0 items-center justify-center z-50 w-full p-4 overflow-x-hidden overflow-y-auto md:inset-0 h-[calc(100%-1rem)] max-h-full`}>
        <div className="relative w-[95%] lg:w-[40%] mt-[25%] lg:mt-[10%] max-h-full mx-auto text-[#333]">
            <div className="relative bg-white shadow pt-3 rounded-[8px]">
                <div className="flex items-start justify-between px-8 pb-3">
                    <h3 className="text-[#121927] text-[20px] font-[600]">
                    </h3>
                    <button onClick={() => setSubmitted(0)} type="button" className="text-gray-300 font-[200] bg-transparent hover:bg-[#F2F2F2] hover:text-black rounded-full p-1.5 ml-auto inline-flex items-center" data-modal-hide="defaultModal">
                        <svg aria-hidden="true" className="w-5 h-5 " fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
                        <span className="sr-only">Close modal</span>
                    </button>
                </div>
                <div className='flex flex-col px-6 text-start space-y-4 items-center'>
                  <img src={success} className="w-[122.5px] h-[122.5px]" />
                  <span className='text-[#121927] font-[700] text-[24px]'>Claim submitted successfully!</span>
                  <span className='text-center'>Claim successfully submitted. Please check your email for confirmation from HMRC.</span>
                </div>
                <div className="flex flex-row justify-center pb-10 space-x-2 px-8 py-4 rounded-b-[8px]">
                    <a href='/' data-modal-hide="defaultModal" type="button" className="border-[1px] border-[#828282] text-[#121927] w-[177px] h-[50px] rounded-[8px] bg-white grid text-center"><p className="my-auto">Go to dashboard</p></a>
                    <a href={'/'+base+'/claims'} data-modal-hide="defaultModal" type="button" className={`border-[1px] w-[150px] h-[50px] bg-[#B30000] rounded-[8px] border-none text-white grid text-center`}><p className="my-auto">See all claims</p></a>
                </div>
            </div>
        </div>
    </div>
    :null}
          <div className='col-span-1 mt-5 border-[1px] rounded-[8px]'>
            <table className="table-auto overflow-x-scroll w-full block divide-y divide-gray-200 ">
              <thead className='bg-[#F7F7F7]'>
                  <tr className=''>
                  <th className='px-6 py-1 text-start text-[#292D32] font-[400] w-[16.6666667%]'>S/N</th>
                  <th className='px-1 py-1 text-start text-[#292D32] font-[400] w-[16.6666667%]'>Period</th>
                  <th className='px-6 py-1 text-start text-[#292D32] font-[400] w-[16.6666667%]'>Creation Date</th>
                  <th className='px-6 py-1 text-start text-[#292D32] font-[400] w-[16.6666667%]'>Total Giving</th>
                  <th className='px-6 py-1 text-start text-[#292D32] font-[400] w-[16.6666667%]'>Status</th>
                  <th className='px-6 py-1 text-start text-[#292D32] font-[400] w-[16.6666667%]'>Action</th>
                  </tr>
              </thead>
              <tbody className='divide-y divide-gray-200'>
                  {givingCurrent.filter((row) =>
                  !searchItem.length || row.name
                  .toString()
                  .toLowerCase()
                  .includes(searchItem.toString().toLowerCase()) 
                  ).map(({id,year,creation_date,total,status,start_date,end_date},index) => {
                      return (
                          <tr onClick={changeToSingle} className='cursor-pointer items-center' key={id}>
                              <td className='px-6 py-3 text-star'>{index+1}</td>
                              <td className='px-1 py-3 text-start text-[#4F4F4F]'>{start_date}<b> - </b>{end_date}</td>
                              <td className='px-6 py-3 text-start text-[#4F4F4F]'>{creation_date}</td>
                              <td className='px-6 py-3 text-start text-[#4F4F4F]'>£{total.toLocaleString()}</td>
                              <td className='px-6 py-3 text-start text-[#4F4F4F]'>
                                <span className={`${status == 0 ? 'bg-[#F6F0EB] text-[#F2994A]' : 'bg-[#E9F0EC] text-[#219653]'} p-2 px-3 rounded-[30px]`}>{status == 0 ? 'Pending' : 'Submitted'}</span>
                              </td>
                              <td className='px-6 py-3 text-start text-[#4F4F4F] items-center my-auto'>
                                <span className='flex flex-row space-x-3 items-center'>
                                <span className='w-[20px] h-[20px]' onClick={() => setSummary(id)}>
                                  <MdNextPlan className='w-[20px] h-[20px]' title='Continue' />
                                </span>
                                <span><img title='Delete' className='w-[20px] h-[20px] cursor-pointer items-center' onClick={() => setDeleteDraft(id)} src={delet} /></span>
                                </span>
                                
                              </td>
                          </tr>
                          )
                      }) 
                  }
              </tbody>
            </table>
          </div>
          <div className='col-span-1 flex flex-row justify-between items-center mt-5'>
            {searchItem? '' : <span className='text-[#333333] text-[16px]'>Showing <span>{givingCurrent.length}</span>/<span>{claimsData.length}</span> results</span>}
            {fetching ? 
              <div className='col-span-8 lg:col-span-6 flex flex-col justify-center items-center '>
                  <ThreeDots height="80" width="80" radius="9" color="#333333" ariaLabel="three-dots-loading"
                  wrapperStyle={{}} wrapperClassName="" visible={true}
                  />
              </div> 
              :
            <div className={`ml-auto grid grid-cols-6 gap-x-4`}>
              {givingArray.length > 0 ?
                  <div className={`mt-5 col-span-6 flex flex-row gap-x-3 mb-5 lg:ml-auto`}>
                      <div onClick={PreviousPage} className={`border-[#BCBCBC] h-[40px] lg:h-auto max-h-[46px] w-[35px] lg:w-auto border-[1px] px-2 lg:px-4 py-1 lg:py-2 rounded-[6px] items-center text-[#BCBCBC] hover:text-[#1D242D] cursor-pointer`}>{`<`}</div>
                      {givingArray.slice(0,5).map((givingArray,index)=> {
                          return (
                              <div key={index} onClick={()=>numberedPage(index)} className={`transitiion-all h-[40px] lg:h-auto max-h-[46px] w-[35px] lg:w-auto text-start duration-500 border-[#BCBCBC] font-[600] text-[18px] border-[1px] px-2 lg:px-4 py-1 lg:py-2 rounded-[6px] items-center ${currentPage == index ? 'text-white bg-[#B30000] border-[#B30000] hover:bg-[#B30000] hover:text-white' :'text-[#292D32] bg-white hover:text-white hover:bg-[#B30000]'} hover:text-[#1D242D] cursor-pointer`}>{index+1}</div>
                          )
                      })}
                      <div onClick={NextPage} className={`border-[#BCBCBC] border-[1px] lg:h-auto max-h-[46px] w-[35px] lg:w-auto px-2 lg:px-4 py-1 lg:py-2 rounded-[6px] items-center text-[#BCBCBC] hover:text-[#1D242D] cursor-pointer`}>{`>`}</div>
                  </div> : ''}
              </div>
            }
          </div>
    </>
  )
}

export default ClaimDraft