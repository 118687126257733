import {useState} from 'react'
import Password from "./password";
import Profile from "./profile";

function Account () {
  const pages = [
    { id: 1, title: "Profile" },
    // { id: 2, title: "Password" },
  ];
  const [aP,setAP] = useState(1)
    
  return (
    <>
      <div className="flex flex-col text-start px-8 mt-5 space-y-4">
        <span className="text-[#121927] font-[600] text-[24px]">
          Account Settings
        </span>
        <div className="border-[1px] rounded-[8px] p-2 flex flex-col lg:flex-row space-y-2 lg:space-y-0 lg:space-x-3 text-center lg:text-start">
          {pages.map(({ id, title }) => {
            return (
              <div
                onClick={() => setAP(id)}
                key={id}
                className={`${
                  aP == id ? "bg-black text-white" : "text-[#4F4F4F]"
                } p-2 cursor-pointer rounded-[4px]`}
              >
                {title}
              </div>
            )
          })}
        </div>
        {aP == 1 ? <Profile></Profile> :  <Password></Password>}
      </div>
    </>
  );
}

export default Account;