import {useState} from "react";
import plus from "../../../images/icons/red plus.svg";
import delet from "../../../images/icons/delete2.svg";


const givings = [
  {
    id: 1,
    serial: "1",
    category: "Offering",
    img: delet,
    
  },
  {
    id: 2,
    serial: "2",
    category: "Tithing",
    img: delet,
    
  },
  {
    id: 3,
    serial: "3",
    category: "Tithe",
    img: delet,
    
  },
 
];

function Giving() {
  const [giving, setGiving] = useState();

  return (
    <>
      {giving ? (
        <div
          id="defaultModal"
          tabIndex="-1"
          aria-hidden="true"
          className={`fixed bg-[rgba(65,_66,_88,_0.62)] top-0 left-0 right-0 z-50 w-full overflow-x-hidden overflow-y-auto md:inset-0 h-[calc(100%-1rem)] max-h-full`}
        >
          <div className="relative w-[95%] rounded-[10px] lg:w-[40%] mt-[25%] lg:mt-[10%] max-h-full mx-auto text-[#333]">
            <div className="relative bg-white shadow pt-3 rounded-[10px]">
              <div className="flex items-start justify-between border-b-[1px] px-8 pb-3">
                <p className="text-[#121927] text-[20px] font-[600]">
                  Add New Giving
                </p>
                <button
                  onClick={() => setGiving(0)}
                  type="button"
                  className="text-gray-300 font-[200] bg-transparent hover:bg-[#F2F2F2] hover:text-black rounded-full p-1.5 ml-auto inline-flex items-center"
                  data-modal-hide="defaultModal"
                >
                  <svg
                    aria-hidden="true"
                    className="w-5 h-5 "
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                      clipRule="evenodd"
                    ></path>
                  </svg>
                  <span className="sr-only">Close modal</span>
                </button>
              </div>
              <div className="p-8">
                <div className="col-span-2 flex flex-col space-y-2 mx-auto">
                  <label>Category Name</label>
                  <div className="w-full relative">
                    <input
                      type="text"
                      className="border-[1px] rounded-[4px] w-full h-[50px] px-3 outline-none"
                      placeholder="Enter the name of the new category"
                    />
                  </div>
                </div>
              </div>
              <div className="flex flex-row justify-end mt-5 space-x-2 px-8 rounded-b-[10px] bg-[#F7F7F7] py-4">
                <button
                  data-modal-hide="defaultModal"
                  type="button"
                  onClick={() => setGiving(0)}
                  className="border-[1px] border-[#828282] text-[#121927] w-[102px] h-[50px] rounded-[8px] bg-white"
                >
                  Cancel
                </button>
                <button
                  data-modal-hide="defaultModal"
                  type="button"
                  className={`border-[1px] w-[150px] h-[50px] bg-[#B30000] rounded-[8px] border-none text-white`}
                >
                  Add New Category
                </button>
              </div>
            </div>
          </div>
        </div>
      ) : null}
      <div className="lg:flex flex-row justify-between items-start">
        <div className="lg:flex lg:flex-col space-y-2">
          <p className="text-[20px] font-[600] text-[#121927]">Giving</p>
          <p className="text-[#4F4F4F] font-[400] text-[16px] w-[280px]">
            You can categories your individual giving into sub categories.
          </p>
        </div>
        <div className="lg:w-[60%]">
          <div className="py-4 lg:flex justify-between">
            <div>
              <p className="text-[16px] font-[600] text-[#121927]">
                Individual giving Categories
              </p>
            </div>
            <div
              className="flex items-center gap-x-2 cursor-pointer"
              onClick={() => setGiving(1)}
            >
              <div>
                <img src={plus} alt="" />
              </div>
              <p className="text-[14px] font-[600] text-[#B30000]">
                Add new category
              </p>
            </div>
          </div>
      <div className="pb-10">
          <div className="w-full overflow-x-auto border-[1px] rounded-[8px] mt-5">
            <table className="w-full">
              <thead className="bg-[#F7F7F7] w-full rounded-[8px]">
                <tr className="text-[#292D32] font-[400]">
                  <th className="px-9 py-3 text-start">S/N</th>
                  <th className="px-9 py-3 text-start">Category</th>
                  <th className="px-9 py-3 text-start"></th>
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-200">
                {givings.map((item, index) => (
                  <tr key={index}>
                    <td className="px-9 py-3 text-start text-[#4F4F4F]">
                      {item.serial}
                    </td>
                    <td className="px-9 py-3 text-start text-[#4F4F4F]">
                      {item.category}
                    </td>
                    <td className="px-9 py-3 text-start text-[#4F4F4F]">
                      <img
                        src={item.img} alt="del"
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Giving;
